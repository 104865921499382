@media print {
  svg,
  [data-print="false"] {
    display: none !important;
  }

  .print-reset,
  button {
    padding: 0 !important;
    margin: 0 !important;
    display: block !important;
    text-align: left !important;
    background: white;
    border: none !important;
    color: black !important;
  }

  .print-mb {
    margin-bottom: 16px !important;
  }

  .print-mb--xl {
    margin-bottom: 24px !important;
  }

  .print-body,
  .print-body span {
    font-size: 16px !important;
    color: black !important;
    text-align: left !important;
    line-height: 1.4 !important;
    margin: 0 !important;
    padding: 0 !important;
    text-overflow: initial !important;
    width: initial !important;
    max-width: initial !important;
  }

  .print-body--right,
  .print-body--right span {
    text-align: right !important;
    width: 400px !important;
  }

  html .print-body--lg {
    font-size: 20px !important;
  }

  .print-heading {
    font-size: 18px !important;
    font-weight: bold !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
    color: black !important;
    text-align: left;
  }

  .print-heading--main {
    font-size: 24px !important;
    font-weight: bold !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
    color: black !important;
    text-align: left !important;
    text-decoration: underline !important;
    position: relative !important;
    width: 100% !important;
  }

  .print-heading--main::before {
    content: url(/etc/odin-print-logo.png);
    height: 16px;
    display: block;
    margin-bottom: 100px;
    position: absolute;
    right: 0;
    top: -25px;
  }

  .print-row {
    border: none !important;
    padding: 0 !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: baseline !important;
    justify-content: space-between !important;
  }
}
