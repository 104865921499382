.styles_radio__YjKGv {
  cursor: pointer;
  user-select: none;
  text-align: left;
}

.styles_radio__YjKGv input {
  display: none;
}

.styles_radio__YjKGv input + span {
  position: relative;
  padding-left: 1.6rem;
}

.styles_radio__YjKGv input + span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  border: 1px solid #f9fafb;
  background: #f9fafb;
}

.styles_radio__YjKGv input + span::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background: var(--colors-brand-yamcha);
  position: absolute;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0, 0);
  transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.styles_radio__YjKGv input:checked + span:after {
  opacity: 1;
  transform: scale(1, 1);
}

.styles_radio--xl__XOEta input + span::before {
  width: 1.6rem;
  height: 1.6rem;
  top: 0rem;
  left: 0rem;
}

.styles_radio--xl__XOEta input + span::after {
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  left: 0.3rem;
}

.styles_radio--no-label__JJ97c {
  font-size: 0px;
  bottom: 0.875rem;
  left: 0.5rem;
}

.styles_radio--xl__XOEta .styles_radio--no-label__JJ97c {
  bottom: 1rem;
  left: 0;
}

.styles_radio--xl__XOEta input + span {
  position: relative;
  padding-left: 1.8rem;
  padding-top: 0;
}

/* vietnamese */
@font-face {
  font-family: '__Qwitcher_Grypen_4d3b37';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ef8d489416e81072-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Qwitcher_Grypen_4d3b37';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/29e3cd2bf2d32cc3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Qwitcher_Grypen_4d3b37';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/726d9113a1aaafbd-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Qwitcher_Grypen_Fallback_4d3b37';src: local("Arial");ascent-override: 147.37%;descent-override: 55.26%;line-gap-override: 0.00%;size-adjust: 54.28%
}.__className_4d3b37 {font-family: '__Qwitcher_Grypen_4d3b37', '__Qwitcher_Grypen_Fallback_4d3b37';font-weight: 400;font-style: normal
}

@media print {
  svg,
  [data-print="false"] {
    display: none !important;
  }

  .print-reset,
  button {
    padding: 0 !important;
    margin: 0 !important;
    display: block !important;
    text-align: left !important;
    background: white;
    border: none !important;
    color: black !important;
  }

  .print-mb {
    margin-bottom: 16px !important;
  }

  .print-mb--xl {
    margin-bottom: 24px !important;
  }

  .print-body,
  .print-body span {
    font-size: 16px !important;
    color: black !important;
    text-align: left !important;
    line-height: 1.4 !important;
    margin: 0 !important;
    padding: 0 !important;
    text-overflow: initial !important;
    width: initial !important;
    max-width: initial !important;
  }

  .print-body--right,
  .print-body--right span {
    text-align: right !important;
    width: 400px !important;
  }

  html .print-body--lg {
    font-size: 20px !important;
  }

  .print-heading {
    font-size: 18px !important;
    font-weight: bold !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
    color: black !important;
    text-align: left;
  }

  .print-heading--main {
    font-size: 24px !important;
    font-weight: bold !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
    color: black !important;
    text-align: left !important;
    text-decoration: underline !important;
    position: relative !important;
    width: 100% !important;
  }

  .print-heading--main::before {
    content: url(/etc/odin-print-logo.png);
    height: 16px;
    display: block;
    margin-bottom: 100px;
    position: absolute;
    right: 0;
    top: -25px;
  }

  .print-row {
    border: none !important;
    padding: 0 !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: baseline !important;
    justify-content: space-between !important;
  }
}

.odin-slider .rc-slider-rail {
  background-color: var(--colors-grey-dune);
}

.odin-slider .rc-slider-track {
  background-color: var(--colors-brand-yamcha);
}

.sonner-toast {
  background: #282828 !important;
  border-color: #383838 !important;
  box-shadow: 0px 3px 14px 0px rgba(255, 255, 255, 0.1) !important;
  font-family: "Muoto", Arial, sans-serif !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.75rem !important;
}

.sonner-toast.sonner-toast--desc {
  align-items: flex-start !important;
}

.sonner-toast.sonner-toast--desc [data-title] {
  margin-top: -2px !important;
  margin-bottom: 0.375rem !important;
}

.sonner-toast [data-title] {
  font-weight: 600 !important;
  margin-bottom: 0 !important;
  line-height: normal;
}

/* hack for zendesk
this allows us to use square or circle launcher type so we can use the snippet on marketing site

*/

/* this stops the chat window from floating above the default launcher  */
iframe[name="Messaging window"] {
  inset: auto 24px 24px auto !important;
}

/* hides the default launcher  */
@media screen and (max-width: 58rem) {
  iframe[id="launcher"] {
    display: none !important;
  }
}

/* enables chat window to go full screen in mobile view (whichhappends by default) but we are overriding that inset above,
this re-enables that behaviour 
*/
@media screen and (max-width: 549px) {
  iframe[name="Messaging window"] {
    inset: 0px !important;
  }
}

/* hides the 'Can we help? popup  */
iframe[title="Message from company"] {
  display: none !important;
}

/* hides the close button of popup  */
iframe[title="Close message"] {
  display: none !important;
}

/* hides the Number of unread messages popup  */
iframe[title="Number of unread messages"] {
  display: none !important;
}

/* hack for zendesk */

@font-face {
  font-family: "Muoto";
  font-weight: 100;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-light.woff2") format("woff2"),
    url("/fonts/muoto/muoto-light.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 300;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-light.woff2") format("woff2"),
    url("/fonts/muoto/muoto-light.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 400;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-regular.woff2") format("woff2"),
    url("/fonts/muoto/muoto-regular.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 500;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-regular.woff2") format("woff2"),
    url("/fonts/muoto/muoto-regular.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 600;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-medium.woff2") format("woff2"),
    url("/fonts/muoto/muoto-medium.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 800;
  font-display: swap;
  src: url("/fonts/muoto/muoto-bold.woff2") format("woff2")
    url("/fonts/muoto/muoto-bold.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 900;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-bold.woff2") format("woff2"),
    url("/fonts/muoto/muoto-bold.woff") format("woff");
}

@layer reset, base, tokens, recipes, utilities;

button {
  cursor: pointer;
}

.logo-animation:hover #logo-dot {
  animation: logoMove 0.9s ease-in-out 0.1s alternate 6;
}

@layer reset {
  html,:host {
    --font-fallback: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-tab-size: 4;
    tab-size: 4;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    font-family: var(--global-font-body, var(--font-fallback));
}

  *,::before,::after,::backdrop,::file-selector-button {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    border-width: 0px;
    border-style: solid;
    border-color: var(--global-color-border, currentColor);
}

  hr {
    height: 0px;
    color: inherit;
    border-top-width: 1px;
}

  body {
    height: 100%;
    line-height: inherit;
}

  img {
    border-style: none;
}

  img,svg,video,canvas,audio,iframe,embed,object {
    display: block;
    vertical-align: middle;
}

  img,video {
    max-width: 100%;
    height: auto;
}

  h1,h2,h3,h4,h5,h6 {
    text-wrap: balance;
    font-size: inherit;
    font-weight: inherit;
}

  p,h1,h2,h3,h4,h5,h6 {
    overflow-wrap: break-word;
}

  ol,ul,menu {
    list-style: none;
}

  button,input:where([type='button'], [type='reset'], [type='submit']),::file-selector-button {
    appearance: button;
    -webkit-appearance: button;
}

  button,input,optgroup,select,textarea,::file-selector-button {
    font: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    letter-spacing: inherit;
    color: inherit;
    background: var(--colors-transparent);
}

  ::placeholder {
    opacity: 1;
    --placeholder-fallback: color-mix(in srgb, currentColor 50%, transparent);
    color: var(--global-color-placeholder, var(--placeholder-fallback));
}

  textarea {
    resize: vertical;
}

  table {
    text-indent: 0px;
    border-collapse: collapse;
    border-color: inherit;
}

  summary {
    display: list-item;
}

  small {
    font-size: 80%;
}

  sub,sup {
    position: relative;
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
}

  sub {
    bottom: -0.25em;
}

  sup {
    top: -0.5em;
}

  dialog {
    padding: 0px;
}

  a {
    color: inherit;
    text-decoration: inherit;
}

  abbr:where([title]) {
    text-decoration: underline dotted;
}

  b,strong {
    font-weight: bolder;
}

  code,kbd,samp,pre {
    --font-mono-fallback: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New';
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-family: var(--global-font-mono, var(--font-mono-fallback));
    font-size: 1em;
}

  progress {
    vertical-align: baseline;
}

  ::-webkit-search-decoration,::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

  ::-webkit-inner-spin-button,::-webkit-outer-spin-button {
    height: auto;
}

  :-moz-ui-invalid {
    box-shadow: none;
}

  :-moz-focusring {
    outline: auto;
}

  [hidden]:where(:not([hidden='until-found'])) {
    display: none !important;
}
}

@layer base {
  :root {
    --made-with-panda: '🐼';
}

  body {
    color: var(--colors-grey-iron);
    background: var(--colors-grey-bunker);
    color-scheme: dark;
    font-family: 'Muoto', Arial, sans-seri;
    font-size: var(--font-sizes-sm);
}

  *,::before,::after,::backdrop {
    --blur: /*-*/ /*-*/;
    --brightness: /*-*/ /*-*/;
    --contrast: /*-*/ /*-*/;
    --grayscale: /*-*/ /*-*/;
    --hue-rotate: /*-*/ /*-*/;
    --invert: /*-*/ /*-*/;
    --saturate: /*-*/ /*-*/;
    --sepia: /*-*/ /*-*/;
    --drop-shadow: /*-*/ /*-*/;
    --backdrop-blur: /*-*/ /*-*/;
    --backdrop-brightness: /*-*/ /*-*/;
    --backdrop-contrast: /*-*/ /*-*/;
    --backdrop-grayscale: /*-*/ /*-*/;
    --backdrop-hue-rotate: /*-*/ /*-*/;
    --backdrop-invert: /*-*/ /*-*/;
    --backdrop-opacity: /*-*/ /*-*/;
    --backdrop-saturate: /*-*/ /*-*/;
    --backdrop-sepia: /*-*/ /*-*/;
    --gradient-from-position: /*-*/ /*-*/;
    --gradient-to-position: /*-*/ /*-*/;
    --gradient-via-position: /*-*/ /*-*/;
    --scroll-snap-strictness: proximity;
    --border-spacing-x: 0;
    --border-spacing-y: 0;
    --translate-x: 0;
    --translate-y: 0;
    --rotate: 0;
    --rotate-x: 0;
    --rotate-y: 0;
    --skew-x: 0;
    --skew-y: 0;
    --scale-x: 1;
    --scale-y: 1;
}
}

@layer tokens {
  :where(:root, :host) {
    --aspect-ratios-square: 1 / 1;
    --aspect-ratios-landscape: 4 / 3;
    --aspect-ratios-portrait: 3 / 4;
    --aspect-ratios-wide: 16 / 9;
    --aspect-ratios-ultrawide: 18 / 5;
    --aspect-ratios-golden: 1.618 / 1;
    --borders-none: none;
    --easings-default: cubic-bezier(0.4, 0, 0.2, 1);
    --easings-linear: linear;
    --easings-in: cubic-bezier(0.4, 0, 1, 1);
    --easings-out: cubic-bezier(0, 0, 0.2, 1);
    --easings-in-out: cubic-bezier(0.4, 0, 0.2, 1);
    --durations-fastest: 50ms;
    --durations-faster: 100ms;
    --durations-fast: 150ms;
    --durations-normal: 200ms;
    --durations-slow: 300ms;
    --durations-slower: 400ms;
    --durations-slowest: 500ms;
    --letter-spacings-tighter: -0.05em;
    --letter-spacings-tight: -0.025em;
    --letter-spacings-normal: 0em;
    --letter-spacings-wide: 0.025em;
    --letter-spacings-wider: 0.05em;
    --letter-spacings-widest: 0.1em;
    --shadows-xs: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --shadows-sm: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --shadows-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --shadows-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --shadows-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --shadows-2xl: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --shadows-inner: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --blurs-sm: 4px;
    --blurs-base: 8px;
    --blurs-md: 12px;
    --blurs-lg: 16px;
    --blurs-xl: 24px;
    --blurs-2xl: 40px;
    --blurs-3xl: 64px;
    --sizes-0: 0rem;
    --sizes-1: 0.25rem;
    --sizes-2: 0.5rem;
    --sizes-3: 0.75rem;
    --sizes-4: 1rem;
    --sizes-5: 1.25rem;
    --sizes-6: 1.5rem;
    --sizes-7: 1.75rem;
    --sizes-8: 2rem;
    --sizes-9: 2.25rem;
    --sizes-10: 2.5rem;
    --sizes-11: 2.75rem;
    --sizes-12: 3rem;
    --sizes-14: 3.5rem;
    --sizes-16: 4rem;
    --sizes-20: 5rem;
    --sizes-24: 6rem;
    --sizes-28: 7rem;
    --sizes-32: 8rem;
    --sizes-36: 9rem;
    --sizes-40: 10rem;
    --sizes-44: 11rem;
    --sizes-48: 12rem;
    --sizes-52: 13rem;
    --sizes-56: 14rem;
    --sizes-60: 15rem;
    --sizes-64: 16rem;
    --sizes-72: 18rem;
    --sizes-80: 20rem;
    --sizes-96: 24rem;
    --sizes-0\.5: 0.125rem;
    --sizes-1\.5: 0.375rem;
    --sizes-2\.5: 0.625rem;
    --sizes-3\.5: 0.875rem;
    --sizes-xs: 20rem;
    --sizes-sm: 24rem;
    --sizes-md: 28rem;
    --sizes-lg: 32rem;
    --sizes-xl: 36rem;
    --sizes-2xl: 42rem;
    --sizes-3xl: 48rem;
    --sizes-4xl: 56rem;
    --sizes-5xl: 64rem;
    --sizes-6xl: 72rem;
    --sizes-7xl: 80rem;
    --sizes-8xl: 90rem;
    --sizes-prose: 65ch;
    --sizes-full: 100%;
    --sizes-min: min-content;
    --sizes-max: max-content;
    --sizes-fit: fit-content;
    --sizes-breakpoint-2xl: 1536px;
    --sizes-breakpoint-base: 0px;
    --sizes-breakpoint-sm: 480px;
    --sizes-breakpoint-md: 768px;
    --sizes-breakpoint-lg: 1024px;
    --sizes-breakpoint-xl: 1280px;
    --animations-spin: spin 1s linear infinite;
    --animations-ping: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    --animations-pulse: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    --animations-bounce: bounce 1s infinite;
    --font-sizes-2xs: 0.5rem;
    --font-sizes-3xl: 1.875rem;
    --font-sizes-4xl: 2.25rem;
    --font-sizes-5xl: 3rem;
    --font-sizes-6xl: 3.75rem;
    --font-sizes-7xl: 4.5rem;
    --font-sizes-8xl: 6rem;
    --font-sizes-9xl: 8rem;
    --font-sizes-xs: 0.813rem;
    --font-sizes-sm: 0.875rem;
    --font-sizes-md: 1rem;
    --font-sizes-ld: 1.125rem;
    --font-sizes-lg: 1.25rem;
    --font-sizes-xl: 1.5rem;
    --font-sizes-2xl: 2rem;
    --fonts-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --fonts-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    --fonts-heading: 'Muoto', Arial, sans-serif;
    --fonts-body: 'Muoto', Arial, sans-serif;
    --fonts-mono: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas, 'DejaVu Sans Mono', monospace;
    --line-heights-3: .75rem;
    --line-heights-4: 1rem;
    --line-heights-5: 1.25rem;
    --line-heights-6: 1.5rem;
    --line-heights-7: 1.75rem;
    --line-heights-8: 2rem;
    --line-heights-9: 2.25rem;
    --line-heights-10: 2.5rem;
    --line-heights-tight: 1.25;
    --line-heights-snug: 1.375;
    --line-heights-relaxed: 1.625;
    --line-heights-loose: 2;
    --line-heights-normal: normal;
    --line-heights-none: 1;
    --line-heights-shorter: 1.25;
    --line-heights-short: 1.375;
    --line-heights-base: 1.5;
    --line-heights-tall: 1.625;
    --line-heights-taller: 2;
    --font-weights-thin: 100;
    --font-weights-extralight: 200;
    --font-weights-medium: 500;
    --font-weights-light: 300;
    --font-weights-normal: 400;
    --font-weights-semibold: 600;
    --font-weights-bold: 700;
    --font-weights-extrabold: 800;
    --font-weights-black: 900;
    --colors-rose-50: #fff1f2;
    --colors-rose-100: #ffe4e6;
    --colors-rose-200: #fecdd3;
    --colors-rose-300: #fda4af;
    --colors-rose-400: #fb7185;
    --colors-rose-500: #f43f5e;
    --colors-rose-600: #e11d48;
    --colors-rose-700: #be123c;
    --colors-rose-800: #9f1239;
    --colors-rose-900: #881337;
    --colors-rose-950: #4c0519;
    --colors-pink-50: #fdf2f8;
    --colors-pink-100: #fce7f3;
    --colors-pink-200: #fbcfe8;
    --colors-pink-300: #f9a8d4;
    --colors-pink-400: #f472b6;
    --colors-pink-500: #ec4899;
    --colors-pink-600: #db2777;
    --colors-pink-700: #be185d;
    --colors-pink-800: #9d174d;
    --colors-pink-900: #831843;
    --colors-pink-950: #500724;
    --colors-fuchsia-50: #fdf4ff;
    --colors-fuchsia-100: #fae8ff;
    --colors-fuchsia-200: #f5d0fe;
    --colors-fuchsia-300: #f0abfc;
    --colors-fuchsia-400: #e879f9;
    --colors-fuchsia-500: #d946ef;
    --colors-fuchsia-600: #c026d3;
    --colors-fuchsia-700: #a21caf;
    --colors-fuchsia-800: #86198f;
    --colors-fuchsia-900: #701a75;
    --colors-fuchsia-950: #4a044e;
    --colors-violet-50: #f5f3ff;
    --colors-violet-100: #ede9fe;
    --colors-violet-200: #ddd6fe;
    --colors-violet-300: #c4b5fd;
    --colors-violet-400: #a78bfa;
    --colors-violet-500: #8b5cf6;
    --colors-violet-600: #7c3aed;
    --colors-violet-700: #6d28d9;
    --colors-violet-800: #5b21b6;
    --colors-violet-900: #4c1d95;
    --colors-violet-950: #2e1065;
    --colors-indigo-50: #eef2ff;
    --colors-indigo-100: #e0e7ff;
    --colors-indigo-200: #c7d2fe;
    --colors-indigo-300: #a5b4fc;
    --colors-indigo-400: #818cf8;
    --colors-indigo-500: #6366f1;
    --colors-indigo-600: #4f46e5;
    --colors-indigo-700: #4338ca;
    --colors-indigo-800: #3730a3;
    --colors-indigo-900: #312e81;
    --colors-indigo-950: #1e1b4b;
    --colors-blue-50: #eff6ff;
    --colors-blue-100: #dbeafe;
    --colors-blue-200: #bfdbfe;
    --colors-blue-300: #93c5fd;
    --colors-blue-400: #60a5fa;
    --colors-blue-500: #3b82f6;
    --colors-blue-600: #2563eb;
    --colors-blue-700: #1d4ed8;
    --colors-blue-800: #1e40af;
    --colors-blue-900: #1e3a8a;
    --colors-blue-950: #172554;
    --colors-sky-50: #f0f9ff;
    --colors-sky-100: #e0f2fe;
    --colors-sky-200: #bae6fd;
    --colors-sky-300: #7dd3fc;
    --colors-sky-400: #38bdf8;
    --colors-sky-500: #0ea5e9;
    --colors-sky-600: #0284c7;
    --colors-sky-700: #0369a1;
    --colors-sky-800: #075985;
    --colors-sky-900: #0c4a6e;
    --colors-sky-950: #082f49;
    --colors-cyan-50: #ecfeff;
    --colors-cyan-100: #cffafe;
    --colors-cyan-200: #a5f3fc;
    --colors-cyan-300: #67e8f9;
    --colors-cyan-400: #22d3ee;
    --colors-cyan-500: #06b6d4;
    --colors-cyan-600: #0891b2;
    --colors-cyan-700: #0e7490;
    --colors-cyan-800: #155e75;
    --colors-cyan-900: #164e63;
    --colors-cyan-950: #083344;
    --colors-teal-50: #f0fdfa;
    --colors-teal-100: #ccfbf1;
    --colors-teal-200: #99f6e4;
    --colors-teal-300: #5eead4;
    --colors-teal-400: #2dd4bf;
    --colors-teal-500: #14b8a6;
    --colors-teal-600: #0d9488;
    --colors-teal-700: #0f766e;
    --colors-teal-800: #115e59;
    --colors-teal-900: #134e4a;
    --colors-teal-950: #042f2e;
    --colors-emerald-50: #ecfdf5;
    --colors-emerald-100: #d1fae5;
    --colors-emerald-200: #a7f3d0;
    --colors-emerald-300: #6ee7b7;
    --colors-emerald-400: #34d399;
    --colors-emerald-500: #10b981;
    --colors-emerald-600: #059669;
    --colors-emerald-700: #047857;
    --colors-emerald-800: #065f46;
    --colors-emerald-900: #064e3b;
    --colors-emerald-950: #022c22;
    --colors-lime-50: #f7fee7;
    --colors-lime-100: #ecfccb;
    --colors-lime-200: #d9f99d;
    --colors-lime-300: #bef264;
    --colors-lime-400: #a3e635;
    --colors-lime-500: #84cc16;
    --colors-lime-600: #65a30d;
    --colors-lime-700: #4d7c0f;
    --colors-lime-800: #3f6212;
    --colors-lime-900: #365314;
    --colors-lime-950: #1a2e05;
    --colors-amber-50: #fffbeb;
    --colors-amber-100: #fef3c7;
    --colors-amber-200: #fde68a;
    --colors-amber-300: #fcd34d;
    --colors-amber-400: #fbbf24;
    --colors-amber-500: #f59e0b;
    --colors-amber-600: #d97706;
    --colors-amber-700: #b45309;
    --colors-amber-800: #92400e;
    --colors-amber-900: #78350f;
    --colors-amber-950: #451a03;
    --colors-orange-50: #fff7ed;
    --colors-orange-100: #ffedd5;
    --colors-orange-200: #fed7aa;
    --colors-orange-300: #fdba74;
    --colors-orange-400: #fb923c;
    --colors-orange-500: #f97316;
    --colors-orange-600: #ea580c;
    --colors-orange-700: #c2410c;
    --colors-orange-800: #9a3412;
    --colors-orange-900: #7c2d12;
    --colors-orange-950: #431407;
    --colors-neutral-50: #fafafa;
    --colors-neutral-100: #f5f5f5;
    --colors-neutral-200: #e5e5e5;
    --colors-neutral-300: #d4d4d4;
    --colors-neutral-400: #a3a3a3;
    --colors-neutral-500: #737373;
    --colors-neutral-600: #525252;
    --colors-neutral-700: #404040;
    --colors-neutral-800: #262626;
    --colors-neutral-900: #171717;
    --colors-neutral-950: #0a0a0a;
    --colors-stone-50: #fafaf9;
    --colors-stone-100: #f5f5f4;
    --colors-stone-200: #e7e5e4;
    --colors-stone-300: #d6d3d1;
    --colors-stone-400: #a8a29e;
    --colors-stone-500: #78716c;
    --colors-stone-600: #57534e;
    --colors-stone-700: #44403c;
    --colors-stone-800: #292524;
    --colors-stone-900: #1c1917;
    --colors-stone-950: #0c0a09;
    --colors-zinc-50: #fafafa;
    --colors-zinc-100: #f4f4f5;
    --colors-zinc-200: #e4e4e7;
    --colors-zinc-300: #d4d4d8;
    --colors-zinc-400: #a1a1aa;
    --colors-zinc-500: #71717a;
    --colors-zinc-600: #52525b;
    --colors-zinc-700: #3f3f46;
    --colors-zinc-800: #27272a;
    --colors-zinc-900: #18181b;
    --colors-zinc-950: #09090b;
    --colors-gray-50: #f9fafb;
    --colors-gray-100: #f3f4f6;
    --colors-gray-200: #e5e7eb;
    --colors-gray-300: #d1d5db;
    --colors-gray-400: #9ca3af;
    --colors-gray-500: #6b7280;
    --colors-gray-600: #4b5563;
    --colors-gray-700: #374151;
    --colors-gray-800: #1f2937;
    --colors-gray-900: #111827;
    --colors-gray-950: #030712;
    --colors-slate-50: #f8fafc;
    --colors-slate-100: #f1f5f9;
    --colors-slate-200: #e2e8f0;
    --colors-slate-300: #cbd5e1;
    --colors-slate-400: #94a3b8;
    --colors-slate-500: #64748b;
    --colors-slate-600: #475569;
    --colors-slate-700: #334155;
    --colors-slate-800: #1e293b;
    --colors-slate-900: #0f172a;
    --colors-slate-950: #020617;
    --colors-transparent: transparent;
    --colors-current: currentColor;
    --colors-black: #000000;
    --colors-white: #FFFFFF;
    --colors-whitewash: rgba(256, 256, 256, 0.1);
    --colors-grey-bunker: #161616;
    --colors-grey-woodsmoke: #1E1E1E;
    --colors-grey-minecraft: #282828;
    --colors-grey-dune: #383838;
    --colors-grey-gunsmoke: #838383;
    --colors-grey-iron: #D1D5DB;
    --colors-grey-alabaster: #F9FAFB;
    --colors-brand-krilin: #EAB189;
    --colors-brand-yamcha: #D7905E;
    --colors-purple-50: #faf5ff;
    --colors-purple-100: #f3e8ff;
    --colors-purple-200: #e9d5ff;
    --colors-purple-300: #d8b4fe;
    --colors-purple-400: #c084fc;
    --colors-purple-500: #a855f7;
    --colors-purple-600: #9333ea;
    --colors-purple-700: #7e22ce;
    --colors-purple-800: #6b21a8;
    --colors-purple-900: #581c87;
    --colors-purple-950: #3b0764;
    --colors-purple-fauve: #9477F3;
    --colors-purple-chalk: #ECE9FD;
    --colors-green-50: #f0fdf4;
    --colors-green-100: #dcfce7;
    --colors-green-200: #bbf7d0;
    --colors-green-300: #86efac;
    --colors-green-400: #4ade80;
    --colors-green-500: #22c55e;
    --colors-green-600: #16a34a;
    --colors-green-700: #15803d;
    --colors-green-800: #166534;
    --colors-green-900: #14532d;
    --colors-green-950: #052e16;
    --colors-green-forest: #236241;
    --colors-green-moss: #47B47B;
    --colors-green-piccolo: #47B47B;
    --colors-green-swamp: #EEFEF4;
    --colors-red-50: #fef2f2;
    --colors-red-100: #fee2e2;
    --colors-red-200: #fecaca;
    --colors-red-300: #fca5a5;
    --colors-red-400: #f87171;
    --colors-red-500: #ef4444;
    --colors-red-600: #dc2626;
    --colors-red-700: #b91c1c;
    --colors-red-800: #991b1b;
    --colors-red-900: #7f1d1d;
    --colors-red-950: #450a0a;
    --colors-red-jiren: #A91919;
    --colors-red-firebrick: #B71C1C;
    --colors-red-salmon: #F98B85;
    --colors-red-mist: #FDE6E6;
    --colors-yellow-50: #fefce8;
    --colors-yellow-100: #fef9c3;
    --colors-yellow-200: #fef08a;
    --colors-yellow-300: #fde047;
    --colors-yellow-400: #facc15;
    --colors-yellow-500: #eab308;
    --colors-yellow-600: #ca8a04;
    --colors-yellow-700: #a16207;
    --colors-yellow-800: #854d0e;
    --colors-yellow-900: #713f12;
    --colors-yellow-950: #422006;
    --colors-yellow-vesuvius: #B45309;
    --colors-yellow-yellowsea: #F7A500;
    --colors-yellow-coral: #F6814F;
    --colors-yellow-cream: #FFFBEB;
    --radii-xs: 0.125rem;
    --radii-4xl: 2rem;
    --radii-none: 0;
    --radii-sm: 0.125rem;
    --radii-base: 0.25rem;
    --radii-md: 0.375rem;
    --radii-lg: 0.5rem;
    --radii-xl: 0.75rem;
    --radii-2xl: 1rem;
    --radii-3xl: 1.5rem;
    --radii-full: 9999px;
    --spacing-0: 0rem;
    --spacing-1: 0.25rem;
    --spacing-2: 0.5rem;
    --spacing-3: 0.75rem;
    --spacing-4: 1rem;
    --spacing-5: 1.25rem;
    --spacing-6: 1.5rem;
    --spacing-7: 1.75rem;
    --spacing-8: 2rem;
    --spacing-9: 2.25rem;
    --spacing-10: 2.5rem;
    --spacing-11: 2.75rem;
    --spacing-12: 3rem;
    --spacing-14: 3.5rem;
    --spacing-16: 4rem;
    --spacing-20: 5rem;
    --spacing-24: 6rem;
    --spacing-28: 7rem;
    --spacing-32: 8rem;
    --spacing-36: 9rem;
    --spacing-40: 10rem;
    --spacing-44: 11rem;
    --spacing-48: 12rem;
    --spacing-52: 13rem;
    --spacing-56: 14rem;
    --spacing-60: 15rem;
    --spacing-64: 16rem;
    --spacing-72: 18rem;
    --spacing-80: 20rem;
    --spacing-96: 24rem;
    --spacing-1px: 1px;
    --spacing-0\.5: 0.125rem;
    --spacing-xs: 0.25rem;
    --spacing-s: 0.5rem;
    --spacing-m: 1rem;
    --spacing-l: 1.5rem;
    --spacing-xl: 2rem;
    --spacing-2xl: 2.5rem;
    --spacing-3xl: 3rem;
    --spacing-1\.5: 0.375rem;
    --spacing-2\.5: 0.625rem;
    --spacing-3\.5: 0.875rem;
    --z-index-callout: 600;
    --z-index-nav: 900;
    --z-index-floating: 2000;
    --z-index-banner: 5000;
    --z-index-tooltip: 7000;
    --z-index-modal: 8000;
    --breakpoints-2xl: 1536px;
    --breakpoints-base: 0px;
    --breakpoints-sm: 480px;
    --breakpoints-md: 768px;
    --breakpoints-lg: 1024px;
    --breakpoints-xl: 1280px;
}

  @keyframes spin {
    to {
      transform: rotate(360deg);
}
}

  @keyframes ping {
    75%,100% {
      transform: scale(2);
      opacity: 0;
}
}

  @keyframes pulse {
    50% {
      opacity: 0.5;
}
}

  @keyframes bounce {
    0%,100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8,0,1,1);
}

    50% {
      transform: none;
      animation-timing-function: cubic-bezier(0,0,0.2,1);
}
}

  @keyframes fadein {
    0% {
      opacity: 0;
}

    100% {
      opacity: 1;
}
}

  @keyframes scaleup {
    0% {
      transform: scale(0);
}

    100% {
      transform: scale(100%);
}
}

  @keyframes fadeup {
    0% {
      opacity: 0.5;
      transform: scale(95%);
}

    100% {
      opacity: 1;
      transform: scale(100%);
}
}

  @keyframes logoMove {
    0% {
      transform: translate(0, 0);
}

    25% {
      transform: translate(-8px, 0);
}

    50% {
      transform: translate(-8px, -8px);
}

    75% {
      transform: translate(0, -8px);
}

    100% {
      transform: translate(0, 0);
}
}
}

@layer utilities {
  .all_unset {
    all: unset;
}

  .pos_bottom {
    position: bottom;
}

  .vis_hidden {
    visibility: hidden;
}

  .h_11\.9375rem {
    height: 11.9375rem;
}

  .bg_grey\.woodsmoke {
    background: var(--colors-grey-woodsmoke);
}

  .d_flex {
    display: flex;
}

  .gap_3 {
    gap: var(--spacing-3);
}

  .w_100\% {
    width: 100%;
}

  .gap_10px {
    gap: 10px;
}

  .gap_6 {
    gap: var(--spacing-6);
}

  .gap_4 {
    gap: var(--spacing-4);
}

  .w_7rem {
    width: 7rem;
}

  .h_7rem {
    height: 7rem;
}

  .bx-sh_0px_0px_46px_8px_\#00000066 {
    box-shadow: 0px 0px 46px 8px #00000066;
}

  .c_grey\.gunsmoke {
    color: var(--colors-grey-gunsmoke);
}

  .\--thickness_1px {
    --thickness: 1px;
}

  .max-w_37\.5rem {
    max-width: 37.5rem;
}

  .op_0\.8 {
    opacity: 0.8;
}

  .p_l {
    padding: var(--spacing-l);
}

  .h_100\% {
    height: 100%;
}

  .gap_2 {
    gap: var(--spacing-2);
}

  .h_full {
    height: var(--sizes-full);
}

  .ta_center {
    text-align: center;
}

  .max-w_80 {
    max-width: var(--sizes-80);
}

  .gap_5rem {
    gap: 5rem;
}

  .gap_s {
    gap: var(--spacing-s);
}

  .gap_m {
    gap: var(--spacing-m);
}

  .max-w_70\% {
    max-width: 70%;
}

  .w_90\% {
    width: 90%;
}

  .w_4\.5rem {
    width: 4.5rem;
}

  .h_4\.5rem {
    height: 4.5rem;
}

  .white-space_nowrap {
    white-space: nowrap;
}

  .my_s {
    margin-block: var(--spacing-s);
}

  .max-w_34rem {
    max-width: 34rem;
}

  .mx_auto {
    margin-inline: auto;
}

  .gap_1\.5rem {
    gap: 1.5rem;
}

  .gap_1 {
    gap: var(--spacing-1);
}

  .c_yellow\.vesuvius {
    color: var(--colors-yellow-vesuvius);
}

  .w_70\% {
    width: 70%;
}

  .d_inline {
    display: inline;
}

  .gap_0\.5rem {
    gap: 0.5rem;
}

  .c_grey\.bunker {
    color: var(--colors-grey-bunker);
}

  .pos_relative {
    position: relative;
}

  .min-h_100vh {
    min-height: 100vh;
}

  .c_white {
    color: var(--colors-white);
}

  .pos_bottom-center {
    position: bottom-center;
}

  .pos_top-center {
    position: top-center;
}

  .gap_xs {
    gap: var(--spacing-xs);
}

  .w_1rem {
    width: 1rem;
}

  .gap_1rem {
    gap: 1rem;
}

  .c_transparent\! {
    color: var(--colors-transparent) !important;
}

  .trs_none {
    transition: none;
}

  .op_0\.9 {
    opacity: 0.9;
}

  .d_inline-flex {
    display: inline-flex;
}

  .bdr_md {
    border-radius: var(--radii-md);
}

  .trs_background_\.2s_ease-in-out {
    transition: background .2s ease-in-out;
}

  .us_none {
    -webkit-user-select: none;
    user-select: none;
}

  .min-w_1rem {
    min-width: 1rem;
}

  .c_grey\.bunker\! {
    color: var(--colors-grey-bunker) !important;
}

  .c_red\.salmon {
    color: var(--colors-red-salmon);
}

  .trs_background_\.3s_ease-in-out {
    transition: background .3s ease-in-out;
}

  .bg-grad_linear\(to-b\,_\#D33F3F\,_red\.firebrick\) {
    --gradient-stops: var(--gradient-via-stops, var(--gradient-from) var(--gradient-from-position), var(--gradient-to) var(--gradient-to-position));
    --gradient: var(--gradient-via-stops, var(--gradient-stops));
    background-image: linear(to-b, #D33F3F, red.firebrick);
}

  .c_brand\.krilin {
    color: var(--colors-brand-krilin);
}

  .p_0\! {
    padding: var(--spacing-0) !important;
}

  .bg_transparent {
    background: var(--colors-transparent);
}

  .px_m {
    padding-inline: var(--spacing-m);
}

  .h_2rem {
    height: 2rem;
}

  .px_l {
    padding-inline: var(--spacing-l);
}

  .h_2\.5rem {
    height: 2.5rem;
}

  .d_none {
    display: none;
}

  .bd_none {
    border: var(--borders-none);
}

  .bdr_lg {
    border-radius: var(--radii-lg);
}

  .c_grey\.iron {
    color: var(--colors-grey-iron);
}

  .bg_linear-gradient\(to_right\,_\#FF6F00\,_\#AD1457\) {
    background: linear-gradient(to right, #FF6F00, #AD1457);
}

  .p_1px {
    padding: var(--spacing-1px);
}

  .p_6px {
    padding: 6px;
}

  .d_inline-block {
    display: inline-block;
}

  .gap_8 {
    gap: var(--spacing-8);
}

  .gap_0 {
    gap: var(--spacing-0);
}

  .c_white\! {
    color: var(--colors-white) !important;
}

  .white-space_normal {
    white-space: normal;
}

  .ta_right {
    text-align: right;
}

  .us_auto {
    -webkit-user-select: auto;
    user-select: auto;
}

  .max-w_14rem {
    max-width: 14rem;
}

  .h_1rem {
    height: 1rem;
}

  .fill_none {
    fill: none;
}

  .d_semibold {
    display: semibold;
}

  .gap_0\.25rem {
    gap: 0.25rem;
}

  .w_400px {
    width: 400px;
}

  .my_0\.25rem {
    margin-block: 0.25rem;
}

  .p_2 {
    padding: var(--spacing-2);
}

  .w_8rem {
    width: 8rem;
}

  .h_1\.3em {
    height: 1.3em;
}

  .w_1\.3em {
    width: 1.3em;
}

  .c_grey\.alabaster {
    color: var(--colors-grey-alabaster);
}

  .h_55vh {
    height: 55vh;
}

  .bdr_base {
    border-radius: var(--radii-base);
}

  .ov_hidden {
    overflow: hidden;
}

  .gap_10 {
    gap: var(--spacing-10);
}

  .bg_gray\.800 {
    background: var(--colors-gray-800);
}

  .p_4 {
    padding: var(--spacing-4);
}

  .h_auto {
    height: auto;
}

  .w_40px {
    width: 40px;
}

  .trs_0\.2s {
    transition: 0.2s;
}

  .bdr_100\% {
    border-radius: 100%;
}

  .h_40px {
    height: 40px;
}

  .w_16px {
    width: 16px;
}

  .h_16px {
    height: 16px;
}

  .ta_start {
    text-align: start;
}

  .gap_xl {
    gap: var(--spacing-xl);
}

  .td_none {
    text-decoration: none;
}

  .d_block {
    display: block;
}

  .bd-b_1px_solid {
    border-bottom: 1px solid;
}

  .bd-b_none {
    border-bottom: var(--borders-none);
}

  .cursor_pointer {
    cursor: pointer;
}

  .white-space_unset {
    white-space: unset;
}

  .w_2\.3rem {
    width: 2.3rem;
}

  .w_0\.875rem {
    width: 0.875rem;
}

  .h_0\.875rem {
    height: 0.875rem;
}

  .pos_absolute {
    position: absolute;
}

  .will-change_transform {
    will-change: transform;
}

  .trf_translate\(0\,0\) {
    transform: translate(0,0);
}

  .w_350px\! {
    width: 350px !important;
}

  .h_8rem {
    height: 8rem;
}

  .c_red {
    color: red;
}

  .c_aliceblue {
    color: aliceblue;
}

  .c_green {
    color: green;
}

  .bdr_99999px {
    border-radius: 99999px;
}

  .op_0\.7 {
    opacity: 0.7;
}

  .gap_14 {
    gap: var(--spacing-14);
}

  .py_0\.25rem {
    padding-block: 0.25rem;
}

  .px_0 {
    padding-inline: var(--spacing-0);
}

  .c_purple\.fauve {
    color: var(--colors-purple-fauve);
}

  .c_green\.moss {
    color: var(--colors-green-moss);
}

  .c_yellow\.yellowsea {
    color: var(--colors-yellow-yellowsea);
}

  .c_brand\.yamcha {
    color: var(--colors-brand-yamcha);
}

  .w_0\.5rem {
    width: 0.5rem;
}

  .h_0\.5rem {
    height: 0.5rem;
}

  .bdr_full {
    border-radius: var(--radii-full);
}

  .bg_purple\.fauve {
    background: var(--colors-purple-fauve);
}

  .bg_green\.moss {
    background: var(--colors-green-moss);
}

  .bg_yellow\.yellowsea {
    background: var(--colors-yellow-yellowsea);
}

  .bg_grey\.iron {
    background: var(--colors-grey-iron);
}

  .bg_brand\.yamcha {
    background: var(--colors-brand-yamcha);
}

  .w_80 {
    width: var(--sizes-80);
}

  .tov_ellipsis {
    text-overflow: ellipsis;
}

  .w_5rem {
    width: 5rem;
}

  .h_5rem {
    height: 5rem;
}

  .w_auto {
    width: auto;
}

  .max-w_sm {
    max-width: var(--sizes-sm);
}

  .z_modal {
    z-index: var(--z-index-modal);
}

  .anim_fadein_300ms\,_scaleup_100ms {
    animation: fadein 300ms, scaleup 100ms;
}

  .bg_grey\.alabaster {
    background: var(--colors-grey-alabaster);
}

  .c_black {
    color: var(--colors-black);
}

  .bg_grey\.minecraft {
    background: var(--colors-grey-minecraft);
}

  .bx-sh_0px_2px_1\.5rem_\#121212 {
    box-shadow: 0px 2px 1.5rem #121212;
}

  .p_s {
    padding: var(--spacing-s);
}

  .py_s {
    padding-block: var(--spacing-s);
}

  .w_300px {
    width: 300px;
}

  .w_25px {
    width: 25px;
}

  .w_17px {
    width: 17px;
}

  .h_17px {
    height: 17px;
}

  .c_grey\.minecraft {
    color: var(--colors-grey-minecraft);
}

  .w_24px {
    width: 24px;
}

  .c_\#F7A500 {
    color: #F7A500;
}

  .bdr_0\.875rem {
    border-radius: 0.875rem;
}

  .px_s {
    padding-inline: var(--spacing-s);
}

  .py_0\.6rem\! {
    padding-block: 0.6rem !important;
}

  .bg_\#FEFBED {
    background: #FEFBED;
}

  .bg_red\.mist {
    background: var(--colors-red-mist);
}

  .c_\#B45309 {
    color: #B45309;
}

  .c_red\.jiren {
    color: var(--colors-red-jiren);
}

  .max-w_xs {
    max-width: var(--sizes-xs);
}

  .px_6 {
    padding-inline: var(--spacing-6);
}

  .py_4 {
    padding-block: var(--spacing-4);
}

  .w_120px {
    width: 120px;
}

  .h_120px {
    height: 120px;
}

  .bg_black {
    background: var(--colors-black);
}

  .p_8 {
    padding: var(--spacing-8);
}

  .tt_capitalize {
    text-transform: capitalize;
}

  .cursor_default {
    cursor: default;
}

  .bdr_xl {
    border-radius: var(--radii-xl);
}

  .px_1\.5rem {
    padding-inline: 1.5rem;
}

  .py_1rem {
    padding-block: 1rem;
}

  .bx-sh_0px_0px_206px_0px_\#8e8e8e52 {
    box-shadow: 0px 0px 206px 0px #8e8e8e52;
}

  .trs_background-color_\.2s_ease-in-out {
    transition: background-color .2s ease-in-out;
}

  .w_47\% {
    width: 47%;
}

  .py_l {
    padding-block: var(--spacing-l);
}

  .py_0\.1rem {
    padding-block: 0.1rem;
}

  .ta_left {
    text-align: left;
}

  .li-s_inside {
    list-style: inside;
}

  .gap_l {
    gap: var(--spacing-l);
}

  .py_m {
    padding-block: var(--spacing-m);
}

  .m_0 {
    margin: var(--spacing-0);
}

  .p_2xl {
    padding: var(--spacing-2xl);
}

  .bdr_2xl {
    border-radius: var(--radii-2xl);
}

  .p_0 {
    padding: var(--spacing-0);
}

  .op_0\.4 {
    opacity: 0.4;
}

  .z_-1 {
    z-index: -1;
}

  .min-w_0 {
    min-width: var(--sizes-0);
}

  .w_75 {
    width: 75px;
}

  .h_75 {
    height: 75px;
}

  .w_68\% {
    width: 68%;
}

  .py_0\.75rem {
    padding-block: 0.75rem;
}

  .w_2rem {
    width: 2rem;
}

  .w_xs {
    width: var(--sizes-xs);
}

  .w_16 {
    width: var(--sizes-16);
}

  .trf_translateX\(-50\%\) {
    transform: translateX(-50%);
}

  .p_1rem {
    padding: 1rem;
}

  .h_225px {
    height: 225px;
}

  .trs_all_0\.2s {
    transition: all 0.2s;
}

  .gap_0\.75rem {
    gap: 0.75rem;
}

  .w_48px {
    width: 48px;
}

  .h_48px {
    height: 48px;
}

  .px_0\! {
    padding-inline: var(--spacing-0) !important;
}

  .w_2rem\! {
    width: 2rem !important;
}

  .h_2rem\! {
    height: 2rem !important;
}

  .pos_fixed {
    position: fixed;
}

  .trf_translate\(-50\%\,_-50\%\) {
    transform: translate(-50%, -50%);
}

  .z_floating {
    z-index: var(--z-index-floating);
}

  .min-h_200px {
    min-height: 200px;
}

  .stk-w_8 {
    stroke-width: 8;
}

  .w_1\.9rem {
    width: 1.9rem;
}

  .h_1\.9rem {
    height: 1.9rem;
}

  .p_m {
    padding: var(--spacing-m);
}

  .w_12rem {
    width: 12rem;
}

  .w_1\.125rem {
    width: 1.125rem;
}

  .h_1\.125rem {
    height: 1.125rem;
}

  .ring_none {
    outline: var(--borders-none);
}

  .w_14rem {
    width: 14rem;
}

  .z_2000 {
    z-index: 2000;
}

  .bd_1px_solid {
    border: 1px solid;
}

  .bx-sh_rgba\(0\,_0\,_0\,_0\.1\)_0px_0px_0px_1px\,rgba\(0\,_0\,_0\,_0\.2\)_0px_5px_10px\,rgba\(0\,_0\,_0\,_0\.4\)_0px_15px_40px {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,rgba(0, 0, 0, 0.2) 0px 5px 10px,rgba(0, 0, 0, 0.4) 0px 15px 40px;
}

  .cursor_not-allowed {
    cursor: not-allowed;
}

  .pointer-events_none {
    pointer-events: none;
}

  .gap_7 {
    gap: var(--spacing-7);
}

  .px_4 {
    padding-inline: var(--spacing-4);
}

  .min-h_60vh {
    min-height: 60vh;
}

  .w_10 {
    width: var(--sizes-10);
}

  .bg_purple\.chalk {
    background: var(--colors-purple-chalk);
}

  .h_10 {
    height: var(--sizes-10);
}

  .c_\#9477F3 {
    color: #9477F3;
}

  .max-w_md {
    max-width: var(--sizes-md);
}

  .my_l {
    margin-block: var(--spacing-l);
}

  .bdr_none {
    border-radius: var(--radii-none);
}

  .max-w_43rem {
    max-width: 43rem;
}

  .min-h_11\.78rem {
    min-height: 11.78rem;
}

  .min-h_5\.625rem {
    min-height: 5.625rem;
}

  .min-h_15\.35rem {
    min-height: 15.35rem;
}

  .min-h_6\.20rem {
    min-height: 6.20rem;
}

  .min-h_auto {
    min-height: auto;
}

  .w_80\% {
    width: 80%;
}

  .w_32\% {
    width: 32%;
}

  .gap_0\.5 {
    gap: var(--spacing-0\.5);
}

  .bdr-t_xl {
    border-top-left-radius: var(--radii-xl);
    border-top-right-radius: var(--radii-xl);
}

  .bdr-t_none {
    border-top-left-radius: var(--radii-none);
    border-top-right-radius: var(--radii-none);
}

  .min-h_3rem {
    min-height: 3rem;
}

  .z_banner {
    z-index: var(--z-index-banner);
}

  .gap_1\.25rem {
    gap: 1.25rem;
}

  .max-w_62\.5rem {
    max-width: 62.5rem;
}

  .m_auto {
    margin: auto;
}

  .h_550px {
    height: 550px;
}

  .px_2 {
    padding-inline: var(--spacing-2);
}

  .py_8 {
    padding-block: var(--spacing-8);
}

  .max-w_lg {
    max-width: var(--sizes-lg);
}

  .my_4 {
    margin-block: var(--spacing-4);
}

  .max-w_350px {
    max-width: 350px;
}

  .w_14px {
    width: 14px;
}

  .h_14px {
    height: 14px;
}

  .cursor_pointer\! {
    cursor: pointer !important;
}

  .us_none\! {
    -webkit-user-select: none !important;
    user-select: none !important;
}

  .w_40\% {
    width: 40%;
}

  .ta_right\! {
    text-align: right !important;
}

  .gap_24 {
    gap: var(--spacing-24);
}

  .gap_0\! {
    gap: var(--spacing-0) !important;
}

  .trs_color_0\.2s {
    transition: color 0.2s;
}

  .h_1\.5rem {
    height: 1.5rem;
}

  .py_1\.25rem {
    padding-block: 1.25rem;
}

  .min-w_3rem {
    min-width: 3rem;
}

  .bg_pink {
    background: pink;
}

  .bg_yellow {
    background: yellow;
}

  .h_14rem {
    height: 14rem;
}

  .max-w_30rem {
    max-width: 30rem;
}

  .h_15rem {
    height: 15rem;
}

  .py_xl {
    padding-block: var(--spacing-xl);
}

  .h_20rem {
    height: 20rem;
}

  .h_4rem {
    height: 4rem;
}

  .z_nav {
    z-index: var(--z-index-nav);
}

  .pos_static {
    position: static;
}

  .w_4rem {
    width: 4rem;
}

  .p_16px_50px {
    padding: 16px 50px;
}

  .h_100px {
    height: 100px;
}

  .bg_white {
    background: var(--colors-white);
}

  .op_1 {
    opacity: 1;
}

  .op_0\.3 {
    opacity: 0.3;
}

  .bdr_50\% {
    border-radius: 50%;
}

  .h_24px {
    height: 24px;
}

  .trs_all_0\.6s_ease {
    transition: all 0.6s ease;
}

  .flex_1 {
    flex: 1 1 0%;
}

  .h_2px {
    height: 2px;
}

  .w_2\.5rem\! {
    width: 2.5rem !important;
}

  .h_2\.5rem\! {
    height: 2.5rem !important;
}

  .stk_\#275B45 {
    stroke: #275B45;
}

  .stk-w_0\.8rem\! {
    stroke-width: 0.8rem !important;
}

  .stk_green\.moss {
    stroke: var(--colors-green-moss);
}

  .h_10px {
    height: 10px;
}

  .w_10px {
    width: 10px;
}

  .bdr_sm {
    border-radius: var(--radii-sm);
}

  .h_10\.5rem {
    height: 10.5rem;
}

  .w_3\.48438rem {
    width: 3.48438rem;
}

  .h_3\.48438rem {
    height: 3.48438rem;
}

  .bx-sh_0px_0px_14px_0px_\#D7905E {
    box-shadow: 0px 0px 14px 0px #D7905E;
}

  .bg_linear-gradient\(180deg\,_\#E65100_12\.82\%\,_\#BF360C_132\.12\%\) {
    background: linear-gradient(180deg, #E65100 12.82%, #BF360C 132.12%);
}

  .max-w_35rem {
    max-width: 35rem;
}

  .gap_3xl {
    gap: var(--spacing-3xl);
}

  .gap_3\.5rem {
    gap: 3.5rem;
}

  .max-w_500px {
    max-width: 500px;
}

  .bg_grey\.bunker {
    background: var(--colors-grey-bunker);
}

  .py_10 {
    padding-block: var(--spacing-10);
}

  .px_12 {
    padding-inline: var(--spacing-12);
}

  .gap_2\.5rem {
    gap: 2.5rem;
}

  .gap_2rem {
    gap: 2rem;
}

  .w_220px\! {
    width: 220px !important;
}

  .d_grid {
    display: grid;
}

  .w_1\.875rem {
    width: 1.875rem;
}

  .w_450px {
    width: 450px;
}

  .w_3rem {
    width: 3rem;
}

  .h_3rem {
    height: 3rem;
}

  .w_75\% {
    width: 75%;
}

  .py_1 {
    padding-block: var(--spacing-1);
}

  .w_700px {
    width: 700px;
}

  .gap_1\.8rem_1\.8rem {
    gap: 1.8rem 1.8rem;
}

  .h_11\.875rem {
    height: 11.875rem;
}

  .wb_break-word {
    word-break: break-word;
}

  .lc_2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

  .stk-w_2 {
    stroke-width: 2;
}

  .c_\#EAB189 {
    color: #EAB189;
}

  .cursor_auto {
    cursor: auto;
}

  .h_38px {
    height: 38px;
}

  .my_xs {
    margin-block: var(--spacing-xs);
}

  .gap_0\.4rem {
    gap: 0.4rem;
}

  .bd-r_none {
    border-right: var(--borders-none);
}

  .max-h_200px {
    max-height: 200px;
}

  .z_tooltip {
    z-index: var(--z-index-tooltip);
}

  .pointer-events_auto {
    pointer-events: auto;
}

  .c_\#FFFFFF {
    color: #FFFFFF;
}

  .c_\#838383 {
    color: #838383;
}

  .ti_1\.5rem {
    text-indent: 1.5rem;
}

  .py_0 {
    padding-block: var(--spacing-0);
}

  .ti_8\.5rem {
    text-indent: 8.5rem;
}

  .h_1\.25rem {
    height: 1.25rem;
}

  .w_1\.25rem {
    width: 1.25rem;
}

  .ap_none {
    appearance: none;
    -webkit-appearance: none;
}

  .trf_translateY\(-50\%\) {
    transform: translateY(-50%);
}

  .w_1\.2rem {
    width: 1.2rem;
}

  .stk-w_8px {
    stroke-width: 8px;
}

  .h_auto\! {
    height: auto !important;
}

  .ti_1rem {
    text-indent: 1rem;
}

  .w_42 {
    width: 42px;
}

  .h_25 {
    height: 25px;
}

  .bdr_9999 {
    border-radius: 9999px;
}

  .bx-sh_0_1px_5px_token\(colors\.brand\.yamcha\) {
    box-shadow: 0 1px 5px var(--colors-brand-yamcha);
}

  .w_21 {
    width: 21px;
}

  .h_21 {
    height: 21px;
}

  .bx-sh_0_2px_2px_token\(colors\.grey\.gunsmoke\) {
    box-shadow: 0 2px 2px var(--colors-grey-gunsmoke);
}

  .trs_transform_150ms {
    transition: transform 150ms;
}

  .trf_translateX\(2px\) {
    transform: translateX(2px);
}

  .max-w_400px {
    max-width: 400px;
}

  .min-h_7rem {
    min-height: 7rem;
}

  .my_m {
    margin-block: var(--spacing-m);
}

  .w_260 {
    width: 260px;
}

  .h_260 {
    height: 260px;
}

  .w_200 {
    width: 200px;
}

  .h_200 {
    height: 200px;
}

  .bd_30 {
    border: 30px;
}

  .c_0 {
    color: 0;
}

  .w_6rem {
    width: 6rem;
}

  .h_6rem {
    height: 6rem;
}

  .w_95\% {
    width: 95%;
}

  .w_25\% {
    width: 25%;
}

  .sr_true {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

  .trs_\.2s_background_ease {
    transition: .2s background ease;
}

  .w_1\.5rem {
    width: 1.5rem;
}

  .ov_auto {
    overflow: auto;
}

  .bkdp_blur\(8px\) {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

  .inset_0 {
    inset: var(--spacing-0);
}

  .anim_fadein_\.4s_ease-out {
    animation: fadein .4s ease-out;
}

  .will-change_width\,_height {
    will-change: width, height;
}

  .w_40rem {
    width: 40rem;
}

  .max-h_95vh {
    max-height: 95vh;
}

  .bdr_2xl\! {
    border-radius: var(--radii-2xl) !important;
}

  .anim_fadein_\.2s_linear {
    animation: fadein .2s linear;
}

  .trs_background-colour_\.3s_ease {
    transition: background-colour .3s ease;
}

  .w_1\.3rem {
    width: 1.3rem;
}

  .max-h_90vh {
    max-height: 90vh;
}

  .w_20px {
    width: 20px;
}

  .h_20px {
    height: 20px;
}

  .rotate-y_180deg {
    --rotate-y: 180deg;
}

  .op_0\.1 {
    opacity: 0.1;
}

  .op_0\.2 {
    opacity: 0.2;
}

  .trs_\.1s_ease-in-out {
    transition: .1s ease-in-out;
}

  .w_18px {
    width: 18px;
}

  .h_18px {
    height: 18px;
}

  .c_brand\.yamcha\! {
    color: var(--colors-brand-yamcha) !important;
}

  .w_fit-content {
    width: fit-content;
}

  .bdr_8px {
    border-radius: 8px;
}

  .p_3px {
    padding: 3px;
}

  .gap_8px {
    gap: 8px;
}

  .tbl_fixed {
    table-layout: fixed;
}

  .bd-cl_collapse {
    border-collapse: collapse;
}

  .p_8px {
    padding: 8px;
}

  .va_top {
    vertical-align: top;
}

  .w_64\! {
    width: var(--sizes-64) !important;
}

  .w_sm {
    width: var(--sizes-sm);
}

  .w_64 {
    width: var(--sizes-64);
}

  .bd-b_1px {
    border-bottom: 1px;
}

  .trs_padding_0\.2s_ease-in-out {
    transition: padding 0.2s ease-in-out;
}

  .py_0\.5rem {
    padding-block: 0.5rem;
}

  .c_inherit {
    color: inherit;
}

  .w_1\.75rem {
    width: 1.75rem;
}

  .h_1\.75rem {
    height: 1.75rem;
}

  .td_underline {
    text-decoration: underline;
}

  .bg_green\.swamp {
    background: var(--colors-green-swamp);
}

  .c_green\.forest {
    color: var(--colors-green-forest);
}

  .bg_yellow\.cream {
    background: var(--colors-yellow-cream);
}

  .max-w_700px {
    max-width: 700px;
}

  .gap_2xl {
    gap: var(--spacing-2xl);
}

  .h_81\% {
    height: 81%;
}

  .h_81\.65\% {
    height: 81.65%;
}

  .w_0\.125rem {
    width: 0.125rem;
}

  .z_2 {
    z-index: 2;
}

  .max-w_40\% {
    max-width: 40%;
}

  .trs_color_\.2s_ease-in-out {
    transition: color .2s ease-in-out;
}

  .w_14 {
    width: var(--sizes-14);
}

  .z_callout {
    z-index: var(--z-index-callout);
}

  .py_0\.75rem\! {
    padding-block: 0.75rem !important;
}

  .px_m\! {
    padding-inline: var(--spacing-m) !important;
}

  .w_2\.5rem {
    width: 2.5rem;
}

  .lc_1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

  .trs_border_\.1s_ease-in-out {
    transition: border .1s ease-in-out;
}

  .white-space_noWrap {
    white-space: noWrap;
}

  .w_0\.8rem {
    width: 0.8rem;
}

  .w_40\%\! {
    width: 40% !important;
}

  .w_20 {
    width: var(--sizes-20);
}

  .h_20 {
    height: var(--sizes-20);
}

  .w_12 {
    width: var(--sizes-12);
}

  .h_12 {
    height: var(--sizes-12);
}

  .w_35px {
    width: 35px;
}

  .h_30px {
    height: 30px;
}

  .mx_0 {
    margin-inline: var(--spacing-0);
}

  .py_2px {
    padding-block: 2px;
}

  .w_8 {
    width: var(--sizes-8);
}

  .h_8 {
    height: var(--sizes-8);
}

  .bd_none\! {
    border: var(--borders-none) !important;
}

  .min-w_auto {
    min-width: auto;
}

  .trs_\.2s_ease-in-out_background-color {
    transition: .2s ease-in-out background-color;
}

  .bdr_auto {
    border-radius: auto;
}

  .px_0\.375rem {
    padding-inline: 0.375rem;
}

  .trs_\.1s_background_ease-in-out {
    transition: .1s background ease-in-out;
}

  .h_16 {
    height: var(--sizes-16);
}

  .c_\#10B981 {
    color: #10B981;
}

  .w_18 {
    width: 18px;
}

  .h_18 {
    height: 18px;
}

  .c_\#686F81 {
    color: #686F81;
}

  .my_2xl {
    margin-block: var(--spacing-2xl);
}

  .px_0\.25rem {
    padding-inline: 0.25rem;
}

  .mx_unset {
    margin-inline: unset;
}

  .max-w_32rem {
    max-width: 32rem;
}

  .p_0\.45rem\! {
    padding: 0.45rem !important;
}

  .w_1\.1rem {
    width: 1.1rem;
}

  .trs_\.2s_background_easy-in-out {
    transition: .2s background easy-in-out;
}

  .bdr_0\.5rem {
    border-radius: 0.5rem;
}

  .h_450px {
    height: 450px;
}

  .translate-y_-50\% {
    --translate-y: -50%;
}

  .w_2px {
    width: 2px;
}

  .z_3 {
    z-index: 3;
}

  .bd-b_1px_solid_hsla\(0\,_0\%\,_22\%\,_1\)\! {
    border-bottom: 1px solid hsla(0, 0%, 22%, 1) !important;
}

  .z_1 {
    z-index: 1;
}

  .min-w_full {
    min-width: var(--sizes-full);
}

  .w_98\% {
    width: 98%;
}

  .h_1\.8rem {
    height: 1.8rem;
}

  .h_1\.2rem {
    height: 1.2rem;
}

  .max-w_85\% {
    max-width: 85%;
}

  .px_1 {
    padding-inline: var(--spacing-1);
}

  .anim_fadein_\.3s_linear {
    animation: fadein .3s linear;
}

  .tsh_md {
    text-shadow: var(--shadows-md);
}

  .direction_right {
    direction: right;
}

  .gap_58\.39999999999999px {
    gap: 58.39999999999999px;
}

  .w_105\.60000000000001px {
    width: 105.60000000000001px;
}

  .h_105\.60000000000001px {
    height: 105.60000000000001px;
}

  .bx-sh_0px_0px_10px_-5px_rgba\(255\,_255\,_255\,_0\.14\)\,_0px_0px_25px_-5px_rgba\(255\,_255\,_255\,_0\.30\) {
    box-shadow: 0px 0px 10px -5px rgba(255, 255, 255, 0.14), 0px 0px 25px -5px rgba(255, 255, 255, 0.30);
}

  .bdr_3xl {
    border-radius: var(--radii-3xl);
}

  .trs_0\.2s_ease-in-out {
    transition: 0.2s ease-in-out;
}

  .w_96px {
    width: 96px;
}

  .h_96px {
    height: 96px;
}

  .fill_true {
    fill: true;
}

  .w_20rem {
    width: 20rem;
}

  .trs_width_300ms {
    transition: width 300ms;
}

  .trs_border_\.2s_ease-in-out {
    transition: border .2s ease-in-out;
}

  .gap_5 {
    gap: var(--spacing-5);
}

  .max-w_22rem {
    max-width: 22rem;
}

  .w_16rem {
    width: 16rem;
}

  .w_9\.375rem {
    width: 9.375rem;
}

  .h_9\.375rem {
    height: 9.375rem;
}

  .max-w_12rem {
    max-width: 12rem;
}

  .h_8\.5rem {
    height: 8.5rem;
}

  .w_120\% {
    width: 120%;
}

  .min-h_110\% {
    min-height: 110%;
}

  .z_0 {
    z-index: 0;
}

  .min-w_auto\! {
    min-width: auto !important;
}

  .w_auto\! {
    width: auto !important;
}

  .bg_grey\.dune {
    background: var(--colors-grey-dune);
}

  .p_0\.2rem {
    padding: 0.2rem;
}

  .stk-w_1px {
    stroke-width: 1px;
}

  .trs_all_0\.3s_ease-in-out {
    transition: all 0.3s ease-in-out;
}

  .w_12px {
    width: 12px;
}

  .h_12px {
    height: 12px;
}

  .op_0 {
    opacity: 0;
}

  .max-h_500px {
    max-height: 500px;
}

  .max-w_25rem {
    max-width: 25rem;
}

  .max-w_10rem {
    max-width: 10rem;
}

  .c_\#F98B85 {
    color: #F98B85;
}

  .w_16rem\! {
    width: 16rem !important;
}

  .max-h_70vh {
    max-height: 70vh;
}

  .trs_transform_\.2s_ease-in-out {
    transition: transform .2s ease-in-out;
}

  .max-h_40vh {
    max-height: 40vh;
}

  .li-s_decimal {
    list-style: decimal;
}

  .min-w_150px {
    min-width: 150px;
}

  .max-w_18rem {
    max-width: 18rem;
}

  .max-w_15rem {
    max-width: 15rem;
}

  .max-w_16rem {
    max-width: 16rem;
}

  .h_3\.5rem {
    height: 3.5rem;
}

  .w_full {
    width: var(--sizes-full);
}

  .trs_200ms_ease-in-out {
    transition: 200ms ease-in-out;
}

  .h_21px {
    height: 21px;
}

  .w_21px {
    width: 21px;
}

  .w_1\.4rem {
    width: 1.4rem;
}

  .my_2rem {
    margin-block: 2rem;
}

  .min-h_4\.25rem {
    min-height: 4.25rem;
}

  .trs_\.2s_background_ease-in-out {
    transition: .2s background ease-in-out;
}

  .mx_l {
    margin-inline: var(--spacing-l);
}

  .tt_uppercase {
    text-transform: uppercase;
}

  .max-w_full {
    max-width: var(--sizes-full);
}

  .max-w_20rem {
    max-width: 20rem;
}

  .max-w_auto {
    max-width: auto;
}

  .ta_left\! {
    text-align: left !important;
}

  .h_4rem\! {
    height: 4rem !important;
}

  .py_3\.5rem {
    padding-block: 3.5rem;
}

  .py_s\! {
    padding-block: var(--spacing-s) !important;
}

  .py_0\.4rem {
    padding-block: 0.4rem;
}

  .my_0 {
    margin-block: var(--spacing-0);
}

  .min-w_10 {
    min-width: var(--sizes-10);
}

  .p_0\.75rem {
    padding: 0.75rem;
}

  .h_26px {
    height: 26px;
}

  .trs_background-color_0\.2s {
    transition: background-color 0.2s;
}

  .w_15em {
    width: 15em;
}

  .px_0\.75rem {
    padding-inline: 0.75rem;
}

  .bx-sh_0_0_1rem_0px_\#ffffff0f {
    box-shadow: 0 0 1rem 0px #ffffff0f;
}

  .min-h_50vh {
    min-height: 50vh;
}

  .grid-c_1\/-1 {
    grid-column: 1/-1;
}

  .h_6\.6rem {
    height: 6.6rem;
}

  .py_0\.85rem {
    padding-block: 0.85rem;
}

  .px_1\.1rem {
    padding-inline: 1.1rem;
}

  .px_1rem {
    padding-inline: 1rem;
}

  .min-h_250px {
    min-height: 250px;
}

  .p_0\.8125rem {
    padding: 0.8125rem;
}

  .bg_\#262626 {
    background: #262626;
}

  .pos_sticky {
    position: sticky;
}

  .bd-b_1px_solid\! {
    border-bottom: 1px solid !important;
}

  .bdr_0 {
    border-radius: 0;
}

  .c_transparent {
    color: var(--colors-transparent);
}

  .trs_\.2s_ease-in-out {
    transition: .2s ease-in-out;
}

  .gap_0\.1rem {
    gap: 0.1rem;
}

  .wb_break-all {
    word-break: break-all;
}

  .bd-t_1px {
    border-top: 1px;
}

  .w_10rem\! {
    width: 10rem !important;
}

  .w_10rem {
    width: 10rem;
}

  .min-h_inherit {
    min-height: inherit;
}

  .w_50\% {
    width: 50%;
}

  .max-h_80vh {
    max-height: 80vh;
}

  .max-h_18\.75rem {
    max-height: 18.75rem;
}

  .h_70px {
    height: 70px;
}

  .bd-t_1px_solid {
    border-top: 1px solid;
}

  .h_4\.375rem {
    height: 4.375rem;
}

  .min-h_300px {
    min-height: 300px;
}

  .pb_36 {
    padding-bottom: var(--spacing-36);
}

  .bg-i_linear-gradient\(90deg\,_rgba\(30\,58\,138\,1\)_0\%\,_rgba\(234\,177\,137\,0\.6447172619047619\)_35\%\,_rgba\(85\,21\,6\,1\)_100\%\) {
    background-image: linear-gradient(90deg, rgba(30,58,138,1) 0%, rgba(234,177,137,0.6447172619047619) 35%, rgba(85,21,6,1) 100%);
}

  .mt_-5\.5rem {
    margin-top: -5.5rem;
}

  .ai_flex-start {
    align-items: flex-start;
}

  .flex-d_column {
    flex-direction: column;
}

  .mb_6 {
    margin-bottom: var(--spacing-6);
}

  .mb_14 {
    margin-bottom: var(--spacing-14);
}

  .ai_flex-end {
    align-items: flex-end;
}

  .jc_space-between {
    justify-content: space-between;
}

  .flex-d_row {
    flex-direction: row;
}

  .ai_center {
    align-items: center;
}

  .mb_2 {
    margin-bottom: var(--spacing-2);
}

  .mb_4 {
    margin-bottom: var(--spacing-4);
}

  .mb_8 {
    margin-bottom: var(--spacing-8);
}

  .border-block-end-width_var\(--thickness\) {
    border-block-end-width: var(--thickness);
}

  .mt_12 {
    margin-top: var(--spacing-12);
}

  .mt_xl {
    margin-top: var(--spacing-xl);
}

  .pb_l {
    padding-bottom: var(--spacing-l);
}

  .pt_s {
    padding-top: var(--spacing-s);
}

  .mb_m {
    margin-bottom: var(--spacing-m);
}

  .mt_0 {
    margin-top: var(--spacing-0);
}

  .mt_m {
    margin-top: var(--spacing-m);
}

  .jc_flex-start {
    justify-content: flex-start;
}

  .mb_0 {
    margin-bottom: var(--spacing-0);
}

  .mb_12 {
    margin-bottom: var(--spacing-12);
}

  .lh_1\.5rem {
    line-height: 1.5rem;
}

  .mb_1\.5rem {
    margin-bottom: 1.5rem;
}

  .mb_0\.75rem {
    margin-bottom: 0.75rem;
}

  .fw_light {
    font-weight: var(--font-weights-light);
}

  .fs_2xl {
    font-size: var(--font-sizes-2xl);
}

  .jc_center {
    justify-content: center;
}

  .pt_4 {
    padding-top: var(--spacing-4);
}

  .lh_1\.6 {
    line-height: 1.6;
}

  .fw_bold {
    font-weight: var(--font-weights-bold);
}

  .jc_flex-end {
    justify-content: flex-end;
}

  .mb_5 {
    margin-bottom: var(--spacing-5);
}

  .ai_start {
    align-items: start;
}

  .mt_-xs {
    margin-top: calc(var(--spacing-xs) * -1);
}

  .mt_1rem {
    margin-top: 1rem;
}

  .mr_s {
    margin-right: var(--spacing-s);
}

  .mt_s {
    margin-top: var(--spacing-s);
}

  .pb_0\.75rem {
    padding-bottom: 0.75rem;
}

  .fs_md {
    font-size: var(--font-sizes-md);
}

  .fw_500 {
    font-weight: 500;
}

  .lh_1 {
    line-height: 1;
}

  .bd-w_1px {
    border-width: 1px;
}

  .border-style_solid {
    border-style: solid;
}

  .bd-c_brand\.krilin {
    border-color: var(--colors-brand-krilin);
}

  .bg-c_brand\.krilin {
    background-color: var(--colors-brand-krilin);
}

  .bg-c_grey\.minecraft {
    background-color: var(--colors-grey-minecraft);
}

  .bd-c_red\.jiren\! {
    border-color: var(--colors-red-jiren) !important;
}

  .bg-c_red\.jiren {
    background-color: var(--colors-red-jiren);
}

  .fw_600 {
    font-weight: 600;
}

  .bg-c_grey\.woodsmoke {
    background-color: var(--colors-grey-woodsmoke);
}

  .pt_8 {
    padding-top: var(--spacing-8);
}

  .flex-sh_0 {
    flex-shrink: 0;
}

  .fw_semibold {
    font-weight: var(--font-weights-semibold);
}

  .lh_1\.2 {
    line-height: 1.2;
}

  .pb_4 {
    padding-bottom: var(--spacing-4);
}

  .ac_flex-start {
    align-content: flex-start;
}

  .bd-w_0\.5px_0px_0\.5px_0px {
    border-width: 0.5px 0px 0.5px 0px;
}

  .bg-c_grey\.dune {
    background-color: var(--colors-grey-dune);
}

  .mr_m {
    margin-right: var(--spacing-m);
}

  .lh_tight {
    line-height: var(--line-heights-tight);
}

  .lh_relaxed {
    line-height: var(--line-heights-relaxed);
}

  .fs_xs {
    font-size: var(--font-sizes-xs);
}

  .content_Your_investment_amount_net_of_all_fees {
    content: Your investment amount net of all fees;
}

  .fw_400 {
    font-weight: 400;
}

  .fs_inherit {
    font-size: inherit;
}

  .lh_unset {
    line-height: unset;
}

  .bg-c_grey\.bunker {
    background-color: var(--colors-grey-bunker);
}

  .right_0\.25rem {
    right: 0.25rem;
}

  .bottom_0\.25rem {
    bottom: 0.25rem;
}

  .flex-wrap_wrap {
    flex-wrap: wrap;
}

  .bd-c_currentColor\! {
    border-color: currentColor !important;
}

  .bd-w_2px {
    border-width: 2px;
}

  .top_0 {
    top: var(--spacing-0);
}

  .left_0 {
    left: var(--spacing-0);
}

  .bottom_0 {
    bottom: var(--spacing-0);
}

  .right_0 {
    right: var(--spacing-0);
}

  .font-style_normal {
    font-style: normal;
}

  .fw_normal {
    font-weight: var(--font-weights-normal);
}

  .lh_short {
    line-height: var(--line-heights-short);
}

  .bg-c_white {
    background-color: var(--colors-white);
}

  .bd-c_grey\.minecraft {
    border-color: var(--colors-grey-minecraft);
}

  .fs_sm {
    font-size: var(--font-sizes-sm);
}

  .lh_25\.888px {
    line-height: 25.888px;
}

  .ml_s {
    margin-left: var(--spacing-s);
}

  .pr_1\.75rem {
    padding-right: 1.75rem;
}

  .top_3px {
    top: 3px;
}

  .right_3px {
    right: 3px;
}

  .bg-i_linear-gradient\(90deg\,_\#202020_0\%\,_\#303030_100\%\) {
    background-image: linear-gradient(90deg, #202020 0%, #303030 100%);
}

  .fs_0\.875rem {
    font-size: 0.875rem;
}

  .pt_l {
    padding-top: var(--spacing-l);
}

  .bg-s_cover {
    background-size: cover;
}

  .bg-p_center_center {
    background-position: center center;
}

  .fs_xl {
    font-size: var(--font-sizes-xl);
}

  .mb_3 {
    margin-bottom: var(--spacing-3);
}

  .pb_8 {
    padding-bottom: var(--spacing-8);
}

  .mr_6 {
    margin-right: var(--spacing-6);
}

  .pt_0 {
    padding-top: var(--spacing-0);
}

  .pb_s {
    padding-bottom: var(--spacing-s);
}

  .ai_baseline {
    align-items: baseline;
}

  .bd-b-w_1px {
    border-bottom-width: 1px;
}

  .bd-c_grey\.dune {
    border-color: var(--colors-grey-dune);
}

  .font-style_italic {
    font-style: italic;
}

  .fs_1\.1rem {
    font-size: 1.1rem;
}

  .li-pos_outside {
    list-style-position: outside;
}

  .pl_0\.8125rem {
    padding-left: 0.8125rem;
}

  .mt_6\.875rem {
    margin-top: 6.875rem;
}

  .mt_l {
    margin-top: var(--spacing-l);
}

  .mb_xs {
    margin-bottom: var(--spacing-xs);
}

  .fs_ld {
    font-size: var(--font-sizes-ld);
}

  .mr_4 {
    margin-right: var(--spacing-4);
}

  .top_-200px {
    top: -200px;
}

  .pt_xl {
    padding-top: var(--spacing-xl);
}

  .mb_xl {
    margin-bottom: var(--spacing-xl);
}

  .mr_3 {
    margin-right: var(--spacing-3);
}

  .mb_l {
    margin-bottom: var(--spacing-l);
}

  .fs_12px {
    font-size: 12px;
}

  .bottom_20\% {
    bottom: 20%;
}

  .left_50\% {
    left: 50%;
}

  .jc_flex-start\! {
    justify-content: flex-start !important;
}

  .pt_3xl {
    padding-top: var(--spacing-3xl);
}

  .border-style_dashed {
    border-style: dashed;
}

  .mb_1rem {
    margin-bottom: 1rem;
}

  .top_50\% {
    top: 50%;
}

  .right_1rem {
    right: 1rem;
}

  .bottom_0\.8rem {
    bottom: 0.8rem;
}

  .bd-c_\#323232\! {
    border-color: #323232 !important;
}

  .bd-c_grey\.dune\! {
    border-color: var(--colors-grey-dune) !important;
}

  .bg-c_transparent {
    background-color: var(--colors-transparent);
}

  .right_10px {
    right: 10px;
}

  .top_10px {
    top: 10px;
}

  .border-bottom-style_solid {
    border-bottom-style: solid;
}

  .bd-t-w_1px {
    border-top-width: 1px;
}

  .border-top-style_solid {
    border-top-style: solid;
}

  .pb_xs {
    padding-bottom: var(--spacing-xs);
}

  .fw_700\! {
    font-weight: 700 !important;
}

  .fw_700 {
    font-weight: 700;
}

  .lh_1\.4 {
    line-height: 1.4;
}

  .jc_space-around {
    justify-content: space-around;
}

  .pt_20 {
    padding-top: var(--spacing-20);
}

  .mt_8 {
    margin-top: var(--spacing-8);
}

  .mb_20 {
    margin-bottom: var(--spacing-20);
}

  .pr_xs {
    padding-right: var(--spacing-xs);
}

  .pr_0\! {
    padding-right: var(--spacing-0) !important;
}

  .bd-b-c_grey\.dune {
    border-bottom-color: var(--colors-grey-dune);
}

  .pb_1px {
    padding-bottom: var(--spacing-1px);
}

  .pt_m {
    padding-top: var(--spacing-m);
}

  .bd-c_grey\.gunsmoke {
    border-color: var(--colors-grey-gunsmoke);
}

  .fs_0\.85rem\! {
    font-size: 0.85rem !important;
}

  .bd-c_transparent {
    border-color: var(--colors-transparent);
}

  .lh_1\.3 {
    line-height: 1.3;
}

  .pb_6 {
    padding-bottom: var(--spacing-6);
}

  .pl_4 {
    padding-left: var(--spacing-4);
}

  .li-t_disc {
    list-style-type: disc;
}

  .pr_0 {
    padding-right: var(--spacing-0);
}

  .pr_16 {
    padding-right: var(--spacing-16);
}

  .mt_-4 {
    margin-top: calc(var(--spacing-4) * -1);
}

  .flex-d_col {
    flex-direction: col;
}

  .flex-g_1 {
    flex-grow: 1;
}

  .left_unset {
    left: unset;
}

  .bd-r-w_0 {
    border-right-width: 0;
}

  .top_unset {
    top: unset;
}

  .mb_6rem {
    margin-bottom: 6rem;
}

  .top_1\.5rem {
    top: 1.5rem;
}

  .mt_2 {
    margin-top: var(--spacing-2);
}

  .mr_2 {
    margin-right: var(--spacing-2);
}

  .li-t_none {
    list-style-type: none;
}

  .bd-c_white {
    border-color: var(--colors-white);
}

  .fv-num_tabular-nums {
    font-variant-numeric: tabular-nums;
}

  .fs_lg {
    font-size: var(--font-sizes-lg);
}

  .fs_3\.5rem {
    font-size: 3.5rem;
}

  .as_flex-start {
    align-self: flex-start;
}

  .fw_300 {
    font-weight: 300;
}

  .mt_2rem {
    margin-top: 2rem;
}

  .mt_6 {
    margin-top: var(--spacing-6);
}

  .mb_2rem {
    margin-bottom: 2rem;
}

  .mb_0\.25rem {
    margin-bottom: 0.25rem;
}

  .fw_500\! {
    font-weight: 500 !important;
}

  .grid-tc_repeat\(auto-fit\,_minmax\(232px\,_1fr\)\) {
    grid-template-columns: repeat(auto-fit, minmax(232px, 1fr));
}

  .grid-tc_repeat\(1\,_minmax\(0\,_1fr\)\) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

  .left_10rem\! {
    left: 10rem !important;
}

  .left_1\.5rem\! {
    left: 1.5rem !important;
}

  .pt_l\! {
    padding-top: var(--spacing-l) !important;
}

  .pt_2xl {
    padding-top: var(--spacing-2xl);
}

  .pb_0\.25rem {
    padding-bottom: 0.25rem;
}

  .fs_1\.125rem {
    font-size: 1.125rem;
}

  .grid-tr_auto {
    grid-template-rows: auto;
}

  .top_1px {
    top: var(--spacing-1px);
}

  .left_13px {
    left: 13px;
}

  .bd-r-w_1px {
    border-right-width: 1px;
}

  .pr_m {
    padding-right: var(--spacing-m);
}

  .pl_1px {
    padding-left: var(--spacing-1px);
}

  .right_13px {
    right: 13px;
}

  .bd-l-w_1px {
    border-left-width: 1px;
}

  .pl_m {
    padding-left: var(--spacing-m);
}

  .pr_1px {
    padding-right: var(--spacing-1px);
}

  .mb_s {
    margin-bottom: var(--spacing-s);
}

  .ov-x_hidden {
    overflow-x: hidden;
}

  .ov-y_scroll {
    overflow-y: scroll;
}

  .mb_1 {
    margin-bottom: var(--spacing-1);
}

  .mt_xs {
    margin-top: var(--spacing-xs);
}

  .pl_0\.5rem {
    padding-left: 0.5rem;
}

  .pr_1rem {
    padding-right: 1rem;
}

  .left_3px {
    left: 3px;
}

  .top_2\.5px {
    top: 2.5px;
}

  .bd-c_yellow\.vesuvius {
    border-color: var(--colors-yellow-vesuvius);
}

  .pr_2\.rem {
    padding-right: 2.rem;
}

  .pr_2rem {
    padding-right: 2rem;
}

  .right_0\.5rem {
    right: 0.5rem;
}

  .fs_4\.5rem\! {
    font-size: 4.5rem !important;
}

  .top_xs {
    top: var(--spacing-xs);
}

  .mt_0\.5rem {
    margin-top: 0.5rem;
}

  .bg-c_grey\.iron {
    background-color: var(--colors-grey-iron);
}

  .fw_semibold\! {
    font-weight: var(--font-weights-semibold) !important;
}

  .pr_4 {
    padding-right: var(--spacing-4);
}

  .bd-c_red\.salmon {
    border-color: var(--colors-red-salmon);
}

  .bd-c_yellow\.yellowsea\! {
    border-color: var(--colors-yellow-yellowsea) !important;
}

  .pb_xl {
    padding-bottom: var(--spacing-xl);
}

  .pb_0 {
    padding-bottom: var(--spacing-0);
}

  .ov-y_auto {
    overflow-y: auto;
}

  .bg-c_whitewash {
    background-color: var(--colors-whitewash);
}

  .ov-y_hidden\! {
    overflow-y: hidden !important;
}

  .top_0\.5rem {
    top: 0.5rem;
}

  .right_0\.8rem {
    right: 0.8rem;
}

  .bd-c_transparent\! {
    border-color: var(--colors-transparent) !important;
}

  .flex-g_2 {
    flex-grow: 2;
}

  .left_-20px {
    left: -20px;
}

  .right_-20px {
    right: -20px;
}

  .mb_2\.5rem {
    margin-bottom: 2.5rem;
}

  .mt_2\.7rem {
    margin-top: 2.7rem;
}

  .ml_l {
    margin-left: var(--spacing-l);
}

  .fs_14px {
    font-size: 14px;
}

  .mt_1 {
    margin-top: var(--spacing-1);
}

  .mt_2\.5rem {
    margin-top: 2.5rem;
}

  .pl_s {
    padding-left: var(--spacing-s);
}

  .pt_2rem {
    padding-top: 2rem;
}

  .mb_0\! {
    margin-bottom: var(--spacing-0) !important;
}

  .pb_1rem {
    padding-bottom: 1rem;
}

  .mb_3rem {
    margin-bottom: 3rem;
}

  .pt_1rem {
    padding-top: 1rem;
}

  .flex-wrap_nowrap {
    flex-wrap: nowrap;
}

  .bd-b-c_grey\.alabaster {
    border-bottom-color: var(--colors-grey-alabaster);
}

  .pb_0\.5px {
    padding-bottom: 0.5px;
}

  .bottom_-30px {
    bottom: -30px;
}

  .bd-c_\#383838ab\! {
    border-color: #383838ab !important;
}

  .pl_l {
    padding-left: var(--spacing-l);
}

  .left_1\.25rem {
    left: 1.25rem;
}

  .top_3\.7rem {
    top: 3.7rem;
}

  .bg-c_green\.piccolo {
    background-color: var(--colors-green-piccolo);
}

  .pt_12 {
    padding-top: var(--spacing-12);
}

  .pb_3 {
    padding-bottom: var(--spacing-3);
}

  .mt_3\.5rem {
    margin-top: 3.5rem;
}

  .pb_m {
    padding-bottom: var(--spacing-m);
}

  .flex-d_column-reverse {
    flex-direction: column-reverse;
}

  .mt_1\.25rem {
    margin-top: 1.25rem;
}

  .bd-b-c_transparent {
    border-bottom-color: var(--colors-transparent);
}

  .content_Investors_whose_funds_have_been_received\,_among_all_those_invited_to_the_deal\. {
    content: Investors whose funds have been received, among all those invited to the deal.;
}

  .mt_-1 {
    margin-top: calc(var(--spacing-1) * -1);
}

  .bd-c_red-salmon {
    border-color: red-salmon;
}

  .bd-c_grey\.woodsmoke {
    border-color: var(--colors-grey-woodsmoke);
}

  .jc_start {
    justify-content: start;
}

  .mt_-m {
    margin-top: calc(var(--spacing-m) * -1);
}

  .bd-c_grey\.minecraft\! {
    border-color: var(--colors-grey-minecraft) !important;
}

  .bd-w_1 {
    border-width: 1px;
}

  .bg-c_grey\.dune\! {
    background-color: var(--colors-grey-dune) !important;
}

  .bd-b-w_0px {
    border-bottom-width: 0px;
}

  .bdr-tl_md {
    border-top-left-radius: var(--radii-md);
}

  .bdr-tr_md {
    border-top-right-radius: var(--radii-md);
}

  .ov-x_auto {
    overflow-x: auto;
}

  .ov-y_hidden {
    overflow-y: hidden;
}

  .ml_xs {
    margin-left: var(--spacing-xs);
}

  .bd-b-w_2px {
    border-bottom-width: 2px;
}

  .bd-t-w_0 {
    border-top-width: 0;
}

  .bd-l-w_0 {
    border-left-width: 0;
}

  .jc_unset {
    justify-content: unset;
}

  .ov-x_scroll {
    overflow-x: scroll;
}

  .bd-w_0\.5px {
    border-width: 0.5px;
}

  .pt_1\.2rem {
    padding-top: 1.2rem;
}

  .pb_1\.3rem {
    padding-bottom: 1.3rem;
}

  .pb_5rem {
    padding-bottom: 5rem;
}

  .left_-2\.3rem {
    left: -2.3rem;
}

  .top_1\.1rem {
    top: 1.1rem;
}

  .bd-c_green\.piccolo {
    border-color: var(--colors-green-piccolo);
}

  .bottom_-3rem {
    bottom: -3rem;
}

  .ml_-1px {
    margin-left: calc(var(--spacing-1px) * -1);
}

  .lh_1\.1 {
    line-height: 1.1;
}

  .pb_2xl {
    padding-bottom: var(--spacing-2xl);
}

  .mt_2xl {
    margin-top: var(--spacing-2xl);
}

  .mt_-24 {
    margin-top: calc(var(--spacing-24) * -1);
}

  .mr_58\.39999999999999px {
    margin-right: 58.39999999999999px;
}

  .right_-3rem {
    right: -3rem;
}

  .top_2\.6rem {
    top: 2.6rem;
}

  .mr_0\.5rem {
    margin-right: 0.5rem;
}

  .bd-b-w_1 {
    border-bottom-width: 1px;
}

  .top_px {
    top: px;
}

  .content_The_investments_made_by_each_investor_through_your_syndicate {
    content: The investments made by each investor through your syndicate;
}

  .bd-b-c_grey\.dune\! {
    border-bottom-color: var(--colors-grey-dune) !important;
}

  .bd-w_4px {
    border-width: 4px;
}

  .bd-c_grey\.bunker {
    border-color: var(--colors-grey-bunker);
}

  .as_center {
    align-self: center;
}

  .bg-p_center {
    background-position: center;
}

  .left_-10\% {
    left: -10%;
}

  .fw_100 {
    font-weight: 100;
}

  .content_Due_to_improvements_to_our_KYC_and_AML_policies\,_we_must_ask_you_to_create_this_legal_entity_again_for_future_investments\._Your_existing_portfolio_will_not_be_affected\. {
    content: Due to improvements to our KYC and AML policies, we must ask you to create this legal entity again for future investments. Your existing portfolio will not be affected.;
}

  .bg-r_repeat {
    background-repeat: repeat;
}

  .bg-s_6\% {
    background-size: 6%;
}

  .bg-c_brand\.yamcha {
    background-color: var(--colors-brand-yamcha);
}

  .top_-5px {
    top: -5px;
}

  .right_-5px {
    right: -5px;
}

  .fs_5px {
    font-size: 5px;
}

  .pb_2 {
    padding-bottom: var(--spacing-2);
}

  .pl_2 {
    padding-left: var(--spacing-2);
}

  .as_flex-end {
    align-self: flex-end;
}

  .mt_4 {
    margin-top: var(--spacing-4);
}

  .pl_0 {
    padding-left: var(--spacing-0);
}

  .mb_2xl {
    margin-bottom: var(--spacing-2xl);
}

  .ff_mono {
    font-family: var(--fonts-mono);
}

  .pr_s {
    padding-right: var(--spacing-s);
}

  .lh_1\.8 {
    line-height: 1.8;
}

  .mb_-s {
    margin-bottom: calc(var(--spacing-s) * -1);
}

  .ai_stretch {
    align-items: stretch;
}

  .justify-self_flex-end {
    justify-self: flex-end;
}

  .bd-c_brand\.krilin\! {
    border-color: var(--colors-brand-krilin) !important;
}

  .fs_1\.2rem {
    font-size: 1.2rem;
}

  .as_start {
    align-self: start;
}

  .jc_end {
    justify-content: end;
}

  .right_-1\.5rem {
    right: -1.5rem;
}

  .mt_1\.5rem {
    margin-top: 1.5rem;
}

  .bd-c_\#303030 {
    border-color: #303030;
}

  .cg_m {
    column-gap: var(--spacing-m);
}

  .rg_m {
    row-gap: var(--spacing-m);
}

  .content_- {
    content: -;
}

  .bottom_1px {
    bottom: var(--spacing-1px);
}

  .fw_400\! {
    font-weight: 400 !important;
}

  .mt_-s {
    margin-top: calc(var(--spacing-s) * -1);
}

  .bdr-tl_2xl {
    border-top-left-radius: var(--radii-2xl);
}

  .bdr-tr_2xl {
    border-top-right-radius: var(--radii-2xl);
}

  .bd-c_\#262626 {
    border-color: #262626;
}

  .bd-c_\#2d2d2d {
    border-color: #2d2d2d;
}

  .pb_2px\! {
    padding-bottom: 2px !important;
}

  .bd-b-c_white {
    border-bottom-color: var(--colors-white);
}

  .mt_1px {
    margin-top: var(--spacing-1px);
}

  .mb_-1\.15rem {
    margin-bottom: -1.15rem;
}

  .bd-r-w_1px\! {
    border-right-width: 1px !important;
}

  .bd-l-w_0\! {
    border-left-width: 0 !important;
}

  .fs_0\.75rem {
    font-size: 0.75rem;
}

  .content_Your_syndicate_lead_fee\,_charged_to_each_investor_in_this_deal\. {
    content: Your syndicate lead fee, charged to each investor in this deal.;
}

  .fs_1rem {
    font-size: 1rem;
}

  .bd-b-c_\#ffffff0f {
    border-bottom-color: #ffffff0f;
}

  .top_2px {
    top: 2px;
}

  .bdr-bl_2xl {
    border-bottom-left-radius: var(--radii-2xl);
}

  .bdr-br_2xl {
    border-bottom-right-radius: var(--radii-2xl);
}

  .bd-t-c_\#ffffff0f {
    border-top-color: #ffffff0f;
}

  .bd-b-w_none {
    border-bottom-width: none;
}

  .border-bottom-style_none {
    border-bottom-style: none;
}

  .bd-b-c_unset {
    border-bottom-color: unset;
}

  .mb_0\.5 {
    margin-bottom: var(--spacing-0\.5);
}

  .lh_shorter {
    line-height: var(--line-heights-shorter);
}

  .\[\&_\.button-spinner\]\:pos_absolute .button-spinner {
    position: absolute;
}

  .\[\&_\.button-spinner\]\:trf_translate\(-50\%\,_-50\%\) .button-spinner {
    transform: translate(-50%, -50%);
}

  .disabled\:op_0\.5:is(:disabled, [disabled], [data-disabled]) {
    opacity: 0.5;
}

  .disabled\:cursor_not-allowed\!:is(:disabled, [disabled], [data-disabled]) {
    cursor: not-allowed !important;
}

  .disabled\:pointer-events_none:is(:disabled, [disabled], [data-disabled]) {
    pointer-events: none;
}

  .loading\:op_1:is([data-loading], [aria-busy=true]) {
    opacity: 1;
}

  .loading\:bg_brand\.krilin:is([data-loading], [aria-busy=true]) {
    background: var(--colors-brand-krilin);
}

  .loading\:c_grey\.bunker:is([data-loading], [aria-busy=true]) {
    color: var(--colors-grey-bunker);
}

  .loading\:bg_grey\.minecraft:is([data-loading], [aria-busy=true]) {
    background: var(--colors-grey-minecraft);
}

  .loading\:c_white:is([data-loading], [aria-busy=true]) {
    color: var(--colors-white);
}

  .loading\:bg_\#DF4B4B:is([data-loading], [aria-busy=true]) {
    background: #DF4B4B;
}

  .\[\&_fieldset\[disabled\]_input\,_\&_fieldset\[disabled\]_textarea\]\:c_grey\.minecraft fieldset[disabled] input,.\[\&_fieldset\[disabled\]_input\,_\&_fieldset\[disabled\]_textarea\]\:c_grey\.minecraft fieldset[disabled] textarea {
    color: var(--colors-grey-minecraft);
}

  .\[\&_fieldset\[disabled\]_input\,_\&_fieldset\[disabled\]_textarea\]\:cursor_not-allowed fieldset[disabled] input,.\[\&_fieldset\[disabled\]_input\,_\&_fieldset\[disabled\]_textarea\]\:cursor_not-allowed fieldset[disabled] textarea {
    cursor: not-allowed;
}

  .\[\&_fieldset\[disabled\]_\[data-testid\=\'amount-field-symbol\'\]\]\:op_0\.5 fieldset[disabled] [data-testid='amount-field-symbol'] {
    opacity: 0.5;
}

  .\[\&_fieldset\[disabled\]_label\]\:c_grey\.gunsmoke fieldset[disabled] label {
    color: var(--colors-grey-gunsmoke);
}

  .last\:bd-b_none:last-child {
    border-bottom: var(--borders-none);
}

  .\[\&_\[data-selected\]\]\:bdr_lg [data-selected] {
    border-radius: var(--radii-lg);
}

  .\[\&_\[data-selected\]\]\:w_2rem [data-selected] {
    width: 2rem;
}

  .\[\&_\[data-selected\]\]\:h_2rem [data-selected] {
    height: 2rem;
}

  .\[\&_\[data-selected\]\]\:d_flex [data-selected] {
    display: flex;
}

  .\[\&_\[data-selected\]\]\:c_grey\.minecraft [data-selected] {
    color: var(--colors-grey-minecraft);
}

  .selected\:c_grey\.iron:is([aria-selected=true], [data-selected]) {
    color: var(--colors-grey-iron);
}

  .selected\:c_grey\.woodsmoke:is([aria-selected=true], [data-selected]) {
    color: var(--colors-grey-woodsmoke);
}

  .selected\:pos_relative:is([aria-selected=true], [data-selected]) {
    position: relative;
}

  .selected\:c_white:is([aria-selected=true], [data-selected]) {
    color: var(--colors-white);
}

  .\[\&_iframe\]\:min-h_80vh\! iframe {
    min-height: 80vh !important;
}

  .\[\&\:\:-webkit-calendar-picker-indicator\]\:d_none::-webkit-calendar-picker-indicator {
    display: none;
}

  .\[\&\:\:-webkit-calendar-picker-indicator\]\:ap_none::-webkit-calendar-picker-indicator {
    appearance: none;
    -webkit-appearance: none;
}

  .\[\&\:\:-webkit-calendar-picker-indicator\]\:-webkit-appearance_none::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
}

  .\[\&\:\:-webkit-inner-spin-button\]\:d_none::-webkit-inner-spin-button {
    display: none;
}

  .\[\&\:\:-webkit-inner-spin-button\]\:ap_none::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
}

  .\[\&\:\:-webkit-inner-spin-button\]\:-webkit-appearance_none::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

  .disabled\:op_0\.4:is(:disabled, [disabled], [data-disabled]) {
    opacity: 0.4;
}

  .disabled\:cursor_not-allowed:is(:disabled, [disabled], [data-disabled]) {
    cursor: not-allowed;
}

  .\[\&\[data-state\=checked\]\]\:trf_translateX\(19px\)[data-state=checked] {
    transform: translateX(19px);
}

  .placeholder\:c_grey\.gunsmoke::placeholder,.placeholder\:c_grey\.gunsmoke[data-placeholder] {
    color: var(--colors-grey-gunsmoke);
}

  .\[\&\[data-state\=\'open\'\]_svg\]\:trf_rotate\(-180deg\)[data-state='open'] svg {
    transform: rotate(-180deg);
}

  .\[\&\[data-state\=\'open\'\]_path\]\:stk-w_2px[data-state='open'] path {
    stroke-width: 2px;
}

  .\[\&\:first-child\]\:bdr_8px_0px_0px_0px:first-child {
    border-radius: 8px 0px 0px 0px;
}

  .\[\&\:last-child\]\:bdr_0px_8px_0px_0px:last-child {
    border-radius: 0px 8px 0px 0px;
}

  .\[\&\:last-child\]\:bdr_0px_0px_0px_8px:last-child {
    border-radius: 0px 0px 0px 8px;
}

  .\[\&_svg_path\]\:trs_fill_0\.2s_ease-in-out svg path {
    transition: fill 0.2s ease-in-out;
}

  .\[\&_path\]\:trs_\.2s_all_ease-in-out path {
    transition: .2s all ease-in-out;
}

  .default\:px_m:default {
    padding-inline: var(--spacing-m);
}

  .default\:py_1\.25rem:default {
    padding-block: 1.25rem;
}

  .\[\&_span\]\:white-space_initial span {
    white-space: initial;
}

  .\[\&_span\]\:ta_left span {
    text-align: left;
}

  .\[\&_\[data-form-error\=\'true\'\]\]\:d_none\! [data-form-error='true'] {
    display: none !important;
}

  .\[\&_\.button-spinner\]\:top_50\% .button-spinner {
    top: 50%;
}

  .\[\&_\.button-spinner\]\:left_50\% .button-spinner {
    left: 50%;
}

  .loading\:bd-c_brand\.krilin\!:is([data-loading], [aria-busy=true]) {
    border-color: var(--colors-brand-krilin) !important;
}

  .loading\:bd-c_grey\.dune\!:is([data-loading], [aria-busy=true]) {
    border-color: var(--colors-grey-dune) !important;
}

  .loading\:bd-c_red\.jiren\!:is([data-loading], [aria-busy=true]) {
    border-color: var(--colors-red-jiren) !important;
}

  .expanded\:bd-w_2px:is([aria-expanded=true], [data-expanded], [data-state="expanded"]) {
    border-width: 2px;
}

  .expanded\:bd-c_brand\.krilin\!:is([aria-expanded=true], [data-expanded], [data-state="expanded"]) {
    border-color: var(--colors-brand-krilin) !important;
}

  .\[\&_fieldset\[disabled\]_input\,_\&_fieldset\[disabled\]_textarea\]\:bg-c_\#969595 fieldset[disabled] input,.\[\&_fieldset\[disabled\]_input\,_\&_fieldset\[disabled\]_textarea\]\:bg-c_\#969595 fieldset[disabled] textarea {
    background-color: #969595;
}

  .\[\&_fieldset\[disabled\]_input\,_\&_fieldset\[disabled\]_textarea\]\:bd-c_\#969595\! fieldset[disabled] input,.\[\&_fieldset\[disabled\]_input\,_\&_fieldset\[disabled\]_textarea\]\:bd-c_\#969595\! fieldset[disabled] textarea {
    border-color: #969595 !important;
}

  .\[\&_fieldset\[disabled\]_\[data-testid\=\'amount-field-symbol\'\]\]\:bd-c_grey\.minecraft fieldset[disabled] [data-testid='amount-field-symbol'] {
    border-color: var(--colors-grey-minecraft);
}

  .\[\&\:first-child\]\:mt_1rem:first-child {
    margin-top: 1rem;
}

  [data-dragging] .\[\[data-dragging\]_\&\]\:bg-c_grey\.dune {
    background-color: var(--colors-grey-dune);
}

  .disabled\:bg-c_grey\.woodsmoke:is(:disabled, [disabled], [data-disabled]) {
    background-color: var(--colors-grey-woodsmoke);
}

  .selected\:bd-c_brand\.krilin\!:is([aria-selected=true], [data-selected]) {
    border-color: var(--colors-brand-krilin) !important;
}

  .\[\&_\[data-selected\]\]\:flex-d_row [data-selected] {
    flex-direction: row;
}

  .\[\&_\[data-selected\]\]\:ai_center [data-selected] {
    align-items: center;
}

  .\[\&_\[data-selected\]\]\:jc_center [data-selected] {
    justify-content: center;
}

  .\[\&_\[data-selected\]\]\:bg-c_grey\.iron [data-selected] {
    background-color: var(--colors-grey-iron);
}

  .\[\&_\[data-selected\]\]\:fw_600 [data-selected] {
    font-weight: 600;
}

  .selected\:bd-c_brand\.krilin:is([aria-selected=true], [data-selected]) {
    border-color: var(--colors-brand-krilin);
}

  .last\:mr_0:last-child {
    margin-right: var(--spacing-0);
}

  .selected\:bd-c_white:is([aria-selected=true], [data-selected]) {
    border-color: var(--colors-white);
}

  .selected\:bg-c_white:is([aria-selected=true], [data-selected]) {
    background-color: var(--colors-white);
}

  .selected\:bd-b-w_2px:is([aria-selected=true], [data-selected]) {
    border-bottom-width: 2px;
}

  .selected\:bd-b-c_white:is([aria-selected=true], [data-selected]) {
    border-bottom-color: var(--colors-white);
}

  .selected\:top_1\.5px:is([aria-selected=true], [data-selected]) {
    top: 1.5px;
}

  .selected\:bg-c_grey\.dune:is([aria-selected=true], [data-selected]) {
    background-color: var(--colors-grey-dune);
}

  .last\:mb_0:last-child {
    margin-bottom: var(--spacing-0);
}

  .\[\&\[data-active-item\]\]\:bg-c_grey\.bunker[data-active-item] {
    background-color: var(--colors-grey-bunker);
}

  .\[\&\[data-state\=checked\]\]\:bg-c_brand\.yamcha[data-state=checked] {
    background-color: var(--colors-brand-yamcha);
}

  .\[\&\[aria-invalid\]\]\:bd-c_yellow\.vesuvius\![aria-invalid] {
    border-color: var(--colors-yellow-vesuvius) !important;
}

  .selected\:bg-c_grey\.minecraft:is([aria-selected=true], [data-selected]) {
    background-color: var(--colors-grey-minecraft);
}

  .last\:bd-c_transparent\!:last-child {
    border-color: var(--colors-transparent) !important;
}

  .\[\&_span\]\:lh_1\.8 span {
    line-height: 1.8;
}

  .selected\:bg-c_\#2d2d2d\!:is([aria-selected=true], [data-selected]) {
    background-color: #2d2d2d !important;
}

  .\[\&\:focus-within\]\:bd-c_yellow\.vesuvius:focus-within {
    border-color: var(--colors-yellow-vesuvius);
}

  .\[\&\:focus-within\]\:bd-c_brand\.yamcha:focus-within {
    border-color: var(--colors-brand-yamcha);
}

  .focus\:ring_none:is(:focus, [data-focus]) {
    outline: var(--borders-none);
}

  .focus\:bx-sh_none:is(:focus, [data-focus]) {
    box-shadow: none;
}

  .focus\:bg_transparent:is(:focus, [data-focus]) {
    background: var(--colors-transparent);
}

  .focus\:bd_none\!:is(:focus, [data-focus]) {
    border: var(--borders-none) !important;
}

  .focus\:bx-sh_0_0_0_3px_token\(colors\.brand\.yamcha\):is(:focus, [data-focus]) {
    box-shadow: 0 0 0 3px var(--colors-brand-yamcha);
}

  .focusVisible\:bx-sh_0_0_0_3px_token\(colors\.brand\.yamcha\):is(:focus-visible, [data-focus-visible]) {
    box-shadow: 0 0 0 3px var(--colors-brand-yamcha);
}

  .focus\:bd-c_transparent\!:is(:focus, [data-focus]) {
    border-color: var(--colors-transparent) !important;
}

  .focus\:bd-c_brand\.yamcha\!:is(:focus, [data-focus]) {
    border-color: var(--colors-brand-yamcha) !important;
}

  .focus\:border-style_solid:is(:focus, [data-focus]) {
    border-style: solid;
}

  .hover\:bg_brand\.yamcha:is(:hover, [data-hover]) {
    background: var(--colors-brand-yamcha);
}

  .hover\:bg_red\.firebrick:is(:hover, [data-hover]) {
    background: var(--colors-red-firebrick);
}

  .hover\:td_none:is(:hover, [data-hover]) {
    text-decoration: none;
}

  .hover\:bg_transparent:is(:hover, [data-hover]) {
    background: var(--colors-transparent);
}

  .hover\:c_brand\.yamcha:is(:hover, [data-hover]) {
    color: var(--colors-brand-yamcha);
}

  .hover\:c_grey\.alabaster:is(:hover, [data-hover]) {
    color: var(--colors-grey-alabaster);
}

  .hover\:c_grey\.iron:is(:hover, [data-hover]) {
    color: var(--colors-grey-iron);
}

  .hover\:w_105\.60000000000001px:is(:hover, [data-hover]) {
    width: 105.60000000000001px;
}

  .hover\:h_105\.60000000000001px:is(:hover, [data-hover]) {
    height: 105.60000000000001px;
}

  .hover\:bg_rgb\(234_177_137_\/_49\%\):is(:hover, [data-hover]) {
    background: rgb(234 177 137 / 49%);
}

  .group:is(:hover, [data-hover]) .groupHover\:op_1 {
    opacity: 1;
}

  .hover\:bg_grey\.dune:is(:hover, [data-hover]) {
    background: var(--colors-grey-dune);
}

  .hover\:c_white:is(:hover, [data-hover]) {
    color: var(--colors-white);
}

  .hover\:bd-c_brand\.krilin:is(:hover, [data-hover]) {
    border-color: var(--colors-brand-krilin);
}

  .hover\:bd-c_grey\.dune\!:is(:hover, [data-hover]) {
    border-color: var(--colors-grey-dune) !important;
}

  .hover\:bg-c_grey\.dune:is(:hover, [data-hover]) {
    background-color: var(--colors-grey-dune);
}

  .hover\:bd-c_red\.jiren\!:is(:hover, [data-hover]) {
    border-color: var(--colors-red-jiren) !important;
}

  .hover\:bg-c_grey\.minecraft:is(:hover, [data-hover]) {
    background-color: var(--colors-grey-minecraft);
}

  .hover\:bg-c_black:is(:hover, [data-hover]) {
    background-color: var(--colors-black);
}

  .hover\:bd-c_iron:is(:hover, [data-hover]) {
    border-color: iron;
}

  .hover\:bg-c_grey\.woodsmoke:is(:hover, [data-hover]) {
    background-color: var(--colors-grey-woodsmoke);
}

  .hover\:bg-c_grey\.dune\!:is(:hover, [data-hover]) {
    background-color: var(--colors-grey-dune) !important;
}

  .hover\:bg-c_grey\.bunker:is(:hover, [data-hover]) {
    background-color: var(--colors-grey-bunker);
}

  .hover\:bg-c_transparent:is(:hover, [data-hover]) {
    background-color: var(--colors-transparent);
}

  .active\:bg_brand\.yamcha:is(:active, [data-active]) {
    background: var(--colors-brand-yamcha);
}

  .active\:bg_red\.firebrick:is(:active, [data-active]) {
    background: var(--colors-red-firebrick);
}

  .active\:c_brand\.yamcha:is(:active, [data-active]) {
    color: var(--colors-brand-yamcha);
}

  .active\:bg_transparent:is(:active, [data-active]) {
    background: var(--colors-transparent);
}

  .active\:c_white:is(:active, [data-active]) {
    color: var(--colors-white);
}

  .active\:bx-sh_0_0_0_3px_token\(colors\.brand\.yamcha\):is(:active, [data-active]) {
    box-shadow: 0 0 0 3px var(--colors-brand-yamcha);
}

  .active\:op_1:is(:active, [data-active]) {
    opacity: 1;
}

  .active\:bd-c_brand\.krilin:is(:active, [data-active]) {
    border-color: var(--colors-brand-krilin);
}

  .active\:bg-c_grey\.minecraft\!:is(:active, [data-active]) {
    background-color: var(--colors-grey-minecraft) !important;
}

  .active\:bd-c_grey\.dune:is(:active, [data-active]) {
    border-color: var(--colors-grey-dune);
}

  .active\:bg-c_grey\.dune:is(:active, [data-active]) {
    background-color: var(--colors-grey-dune);
}

  .active\:bd-c_red\.jiren\!:is(:active, [data-active]) {
    border-color: var(--colors-red-jiren) !important;
}

  .active\:bg-c_grey\.woodsmoke:is(:active, [data-active]) {
    background-color: var(--colors-grey-woodsmoke);
}

  .active\:bg-c_grey\.minecraft:is(:active, [data-active]) {
    background-color: var(--colors-grey-minecraft);
}

  .loading\:hover\:c_grey\.bunker:is([data-loading], [aria-busy=true]):is(:hover, [data-hover]) {
    color: var(--colors-grey-bunker);
}

  .loading\:hover\:bg_grey\.minecraft:is([data-loading], [aria-busy=true]):is(:hover, [data-hover]) {
    background: var(--colors-grey-minecraft);
}

  .loading\:hover\:c_white:is([data-loading], [aria-busy=true]):is(:hover, [data-hover]) {
    color: var(--colors-white);
}

  .\[\&_fieldset\[disabled\]_\[data-testid\=\'amount-field-symbol\'\]\]\:\[\&_svg_path\]\:stk_grey\.minecraft fieldset[disabled] [data-testid='amount-field-symbol'] svg path {
    stroke: var(--colors-grey-minecraft);
}

  .\[\&_\[data-selected\]\]\:hover\:c_grey\.minecraft [data-selected]:is(:hover, [data-hover]) {
    color: var(--colors-grey-minecraft);
}

  .selected\:hover\:c_grey\.woodsmoke:is([aria-selected=true], [data-selected]):is(:hover, [data-hover]) {
    color: var(--colors-grey-woodsmoke);
}

  .loading\:hover\:bg-c_brand\.krilin:is([data-loading], [aria-busy=true]):is(:hover, [data-hover]) {
    background-color: var(--colors-brand-krilin);
}

  .loading\:hover\:bd-c_brand\.krilin\!:is([data-loading], [aria-busy=true]):is(:hover, [data-hover]) {
    border-color: var(--colors-brand-krilin) !important;
}

  .loading\:hover\:bd-c_grey\.dune\!:is([data-loading], [aria-busy=true]):is(:hover, [data-hover]) {
    border-color: var(--colors-grey-dune) !important;
}

  .disabled\:hover\:bg-c_grey\.woodsmoke:is(:disabled, [disabled], [data-disabled]):is(:hover, [data-hover]) {
    background-color: var(--colors-grey-woodsmoke);
}

  .\[\&_\[data-selected\]\]\:hover\:bg-c_grey\.iron [data-selected]:is(:hover, [data-hover]) {
    background-color: var(--colors-grey-iron);
}

  .selected\:hover\:bd-c_white:is([aria-selected=true], [data-selected]):is(:hover, [data-hover]) {
    border-color: var(--colors-white);
}

  .selected\:hover\:bg-c_white:is([aria-selected=true], [data-selected]):is(:hover, [data-hover]) {
    background-color: var(--colors-white);
}

  .\[\&\[aria-invalid\]\]\:focus\:bd-c_yellow\.vesuvius\![aria-invalid]:is(:focus, [data-focus]) {
    border-color: var(--colors-yellow-vesuvius) !important;
}

  .hover\:disabled\:bg_brand\.yamcha:is(:hover, [data-hover]):is(:disabled, [disabled], [data-disabled]) {
    background: var(--colors-brand-yamcha);
}

  .hover\:disabled\:bg_linear\(to-b\,_\#D33F3F\,_red\.firebrick\):is(:hover, [data-hover]):is(:disabled, [disabled], [data-disabled]) {
    background: linear(to-b, #D33F3F, red.firebrick);
}

  .hover\:\[\&_path\]\:stk_brand\.krilin:is(:hover, [data-hover]) path {
    stroke: var(--colors-brand-krilin);
}

  .hover\:\[\&_svg_path\]\:fill_brand\.yamcha:is(:hover, [data-hover]) svg path {
    fill: var(--colors-brand-yamcha);
}

  .hover\:\[\&_path\]\:fill_grey\.alabaster:is(:hover, [data-hover]) path {
    fill: var(--colors-grey-alabaster);
}

  .hover\:\[\&_path\]\:stk_grey\.alabaster:is(:hover, [data-hover]) path {
    stroke: var(--colors-grey-alabaster);
}

  .hover\:disabled\:bg-c_grey\.minecraft:is(:hover, [data-hover]):is(:disabled, [disabled], [data-disabled]) {
    background-color: var(--colors-grey-minecraft);
}

  .hover\:\[\&\:hover_\[data-row-highlight\=\"true\"\]\]\:bd-b-c_white:is(:hover, [data-hover]):hover [data-row-highlight="true"] {
    border-bottom-color: var(--colors-white);
}

  .hover\:\[\&\:hover_\[data-company-name\=\"true\"\]\]\:bd-b-c_white:is(:hover, [data-hover]):hover [data-company-name="true"] {
    border-bottom-color: var(--colors-white);
}

  .hover\:\[\&_\[data-interactive\]\]\:bd-b-c_grey\.iron:is(:hover, [data-hover]) [data-interactive] {
    border-bottom-color: var(--colors-grey-iron);
}

  @media screen and (min-width: 30rem) {
    .sm\:max-w_100\% {
      max-width: 100%;
}

    .sm\:px_l {
      padding-inline: var(--spacing-l);
}

    .sm\:px_3\.5rem {
      padding-inline: 3.5rem;
}

    .sm\:c_0 {
      color: 0;
}

    .sm\:d_block {
      display: block;
}

    .sm\:p_l {
      padding: var(--spacing-l);
}

    .sm\:h_10\.3rem {
      height: 10.3rem;
}

    .sm\:max-w_80\% {
      max-width: 80%;
}

    .sm\:ta_right\! {
      text-align: right !important;
}

    .sm\:py_xl {
      padding-block: var(--spacing-xl);
}

    .sm\:gap_m {
      gap: var(--spacing-m);
}

    .sm\:max-w_15rem {
      max-width: 15rem;
}

    .sm\:fs_sm {
      font-size: var(--font-sizes-sm);
}

    .sm\:fs_md {
      font-size: var(--font-sizes-md);
}

    .sm\:ai_flex-end {
      align-items: flex-end;
}

    .sm\:jc_space-between {
      justify-content: space-between;
}

    .sm\:fs_lg {
      font-size: var(--font-sizes-lg);
}

    .sm\:pt_0 {
      padding-top: var(--spacing-0);
}

    .sm\:grid-tc_repeat\(2\,_minmax\(0\,_1fr\)\) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
}

    .sm\:flex-d_row {
      flex-direction: row;
}

    .sm\:mt_-48 {
      margin-top: calc(var(--spacing-48) * -1);
}

    .sm\:flex-d_column {
      flex-direction: column;
}

    .sm\:ai_center {
      align-items: center;
}

    .\[\&_span\]\:sm\:ta_right span {
      text-align: right;
}
}

  @media screen and (min-width: 48rem) {
    .md\:c_0\.7 {
      color: 0.7px;
}

    .md\:w_20rem {
      width: 20rem;
}

    .md\:w_23\.75rem {
      width: 23.75rem;
}

    .md\:px_l {
      padding-inline: var(--spacing-l);
}

    .md\:gap_0 {
      gap: var(--spacing-0);
}

    .md\:d_block {
      display: block;
}

    .md\:gap_l {
      gap: var(--spacing-l);
}

    .md\:fs_xl {
      font-size: var(--font-sizes-xl);
}

    .md\:pr_m {
      padding-right: var(--spacing-m);
}

    .md\:grid-tc_repeat\(2\,_minmax\(0\,_1fr\)\) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
}

    .md\:grid-tc_repeat\(3\,_minmax\(0\,_1fr\)\) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
}

    .md\:fs_sm {
      font-size: var(--font-sizes-sm);
}

    .md\:fs_lg {
      font-size: var(--font-sizes-lg);
}

    .md\:pt_2rem {
      padding-top: 2rem;
}

    .md\:flex-d_row {
      flex-direction: row;
}

    .md\:jc_space-between {
      justify-content: space-between;
}

    .md\:ai_flex-start {
      align-items: flex-start;
}

    .md\:ai_flex-end {
      align-items: flex-end;
}

    .md\:fs_1\.625rem {
      font-size: 1.625rem;
}

    .md\:flex-wrap_nowrap {
      flex-wrap: nowrap;
}

    .md\:pr_0\.8125rem {
      padding-right: 0.8125rem;
}
}

  @media screen and (min-width: 64rem) {
    .lg\:max-w_21\.875rem {
      max-width: 21.875rem;
}

    .lg\:d_inline-block {
      display: inline-block;
}

    .lg\:w_15rem {
      width: 15rem;
}

    .lg\:d_table-cell {
      display: table-cell;
}

    .lg\:ta_left {
      text-align: left;
}

    .lg\:w_70\% {
      width: 70%;
}

    .lg\:w_33\.3333\% {
      width: 33.3333%;
}

    .lg\:w_88vw {
      width: 88vw;
}

    .lg\:px_s {
      padding-inline: var(--spacing-s);
}

    .lg\:w_4rem {
      width: 4rem;
}

    .lg\:h_full {
      height: var(--sizes-full);
}

    .lg\:pos_absolute {
      position: absolute;
}

    .lg\:w_68\% {
      width: 68%;
}

    .lg\:p_l {
      padding: var(--spacing-l);
}

    .lg\:d_block {
      display: block;
}

    .lg\:w_auto {
      width: auto;
}

    .lg\:px_0\! {
      padding-inline: var(--spacing-0) !important;
}

    .lg\:ta_left\! {
      text-align: left !important;
}

    .lg\:w_22\%\! {
      width: 22% !important;
}

    .lg\:w_15\% {
      width: 15%;
}

    .lg\:px_m {
      padding-inline: var(--spacing-m);
}

    .lg\:py_m {
      padding-block: var(--spacing-m);
}

    .lg\:gap_s {
      gap: var(--spacing-s);
}

    .lg\:w_16rem {
      width: 16rem;
}

    .lg\:w_14rem {
      width: 14rem;
}

    .lg\:max-w_22rem {
      max-width: 22rem;
}

    .lg\:px_0 {
      padding-inline: var(--spacing-0);
}

    .lg\:mt_3\.7rem {
      margin-top: 3.7rem;
}

    .lg\:mt_0 {
      margin-top: var(--spacing-0);
}

    .lg\:ml_4rem {
      margin-left: 4rem;
}

    .lg\:left_1rem {
      left: 1rem;
}

    .lg\:right_0 {
      right: var(--spacing-0);
}

    .lg\:pr_m {
      padding-right: var(--spacing-m);
}

    .lg\:pr_4 {
      padding-right: var(--spacing-4);
}

    .lg\:flex-d_row {
      flex-direction: row;
}

    .lg\:pr_0 {
      padding-right: var(--spacing-0);
}

    .lg\:left_2rem {
      left: 2rem;
}

    .lg\:bd-r-w_1px {
      border-right-width: 1px;
}

    .lg\:bd-t-w_0 {
      border-top-width: 0;
}

    .lg\:top_0 {
      top: var(--spacing-0);
}

    .lg\:bottom_unset {
      bottom: unset;
}

    .lg\:grid-tc_repeat\(3\,_minmax\(0\,_1fr\)\) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
}

    .lg\:grid-tc_1fr_1fr {
      grid-template-columns: 1fr 1fr;
}

    .lg\:bd-c_grey\.dune\! {
      border-color: var(--colors-grey-dune) !important;
}

    .lg\:pt_2rem {
      padding-top: 2rem;
}

    .lg\:pb_1rem {
      padding-bottom: 1rem;
}

    .lg\:flex-wrap_nowrap {
      flex-wrap: nowrap;
}

    .lg\:left_xs {
      left: var(--spacing-xs);
}

    .lg\:jc_flex-start {
      justify-content: flex-start;
}

    .lg\:mr_0 {
      margin-right: var(--spacing-0);
}

    .lg\:ai_flex-start {
      align-items: flex-start;
}

    .lg\:bg-c_\#0c0c0c {
      background-color: #0c0c0c;
}

    .lg\:bd-b-w_1px {
      border-bottom-width: 1px;
}

    .lg\:bd-t-w_1px {
      border-top-width: 1px;
}

    .lg\:bd-l-w_1px {
      border-left-width: 1px;
}

    .lg\:ov-x_hidden {
      overflow-x: hidden;
}

    .lg\:fs_lg {
      font-size: var(--font-sizes-lg);
}
}

  @media screen and (min-width: 80rem) {
    .xl\:w_20rem {
      width: 20rem;
}

    .xl\:w_22\.5rem {
      width: 22.5rem;
}
}

  @media screen and (max-width: 63.9975rem) {
    .lgDown\:ta_center {
      text-align: center;
}

    .lgDown\:ml_0 {
      margin-left: var(--spacing-0);
}

    .lgDown\:grid-tc_1fr_1fr {
      grid-template-columns: 1fr 1fr;
}

    .lgDown\:ai_center {
      align-items: center;
}
}

  @media screen and (max-width: 29.9975rem) {
    .smDown\:d_grid {
      display: grid;
}

    .smDown\:grid-tc_1fr {
      grid-template-columns: 1fr;
}

    .smDown\:flex-d_column {
      flex-direction: column;
}

    .smDown\:flex-wrap_wrap {
      flex-wrap: wrap;
}

    .smDown\:grid-tc_repeat\(2\,_minmax\(0\,_1fr\)\) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
}

    .smDown\:jc_center {
      justify-content: center;
}
}
}

.rc-slider {
  position: relative;
  width: 100%;
  height: 14px;
  padding: 5px 0;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #e9e9e9;
  border-radius: 6px;
}
.rc-slider-track,
.rc-slider-tracks {
  position: absolute;
  height: 4px;
  background-color: #abe2fb;
  border-radius: 6px;
}
.rc-slider-track-draggable {
  z-index: 1;
  box-sizing: content-box;
  background-clip: content-box;
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  transform: translateY(-5px);
}
.rc-slider-handle {
  position: absolute;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #96dbfa;
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  opacity: 0.8;
  user-select: none;
  touch-action: pan-x;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging-delete {
  opacity: 0;
}
.rc-slider-handle:focus {
  outline: none;
  box-shadow: none;
}
.rc-slider-handle:focus-visible {
  border-color: #2db7f5;
  box-shadow: 0 0 0 3px #96dbfa;
}
.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #999;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  cursor: pointer;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  background-color: #fff;
  border-color: #ccc;
  box-shadow: none;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  width: 4px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-track {
  bottom: 0;
  left: 5px;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track-draggable {
  border-top: 0;
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 0;
  border-left: 5px solid rgba(0, 0, 0, 0);
  transform: translateX(-5px);
}
.rc-slider-vertical .rc-slider-handle {
  position: absolute;
  z-index: 1;
  margin-top: 0;
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  width: 4px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-dot {
  margin-left: -2px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  display: block !important;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  display: block !important;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    transform: scale(0, 0);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0, 0);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.rc-slider-tooltip {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  min-width: 24px;
  height: 24px;
  padding: 6px 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  user-select: none;
  overflow: hidden;
}

.react-loading-skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: none; /* Disable animation */
  }
}

