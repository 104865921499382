@font-face {
  font-family: "Muoto";
  font-weight: 100;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-light.woff2") format("woff2"),
    url("/fonts/muoto/muoto-light.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 300;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-light.woff2") format("woff2"),
    url("/fonts/muoto/muoto-light.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 400;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-regular.woff2") format("woff2"),
    url("/fonts/muoto/muoto-regular.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 500;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-regular.woff2") format("woff2"),
    url("/fonts/muoto/muoto-regular.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 600;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-medium.woff2") format("woff2"),
    url("/fonts/muoto/muoto-medium.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 800;
  font-display: swap;
  src: url("/fonts/muoto/muoto-bold.woff2") format("woff2")
    url("/fonts/muoto/muoto-bold.woff") format("woff");
}

@font-face {
  font-family: "Muoto";
  font-weight: 900;
  font-display: swap;
  src:
    url("/fonts/muoto/muoto-bold.woff2") format("woff2"),
    url("/fonts/muoto/muoto-bold.woff") format("woff");
}
