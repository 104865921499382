.sonner-toast {
  background: #282828 !important;
  border-color: #383838 !important;
  box-shadow: 0px 3px 14px 0px rgba(255, 255, 255, 0.1) !important;
  font-family: "Muoto", Arial, sans-serif !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.75rem !important;
}

.sonner-toast.sonner-toast--desc {
  align-items: flex-start !important;
}

.sonner-toast.sonner-toast--desc [data-title] {
  margin-top: -2px !important;
  margin-bottom: 0.375rem !important;
}

.sonner-toast [data-title] {
  font-weight: 600 !important;
  margin-bottom: 0 !important;
  line-height: normal;
}
