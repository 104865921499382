@layer reset, base, tokens, recipes, utilities;
@import "./print.css";
@import "./slider.css";
@import "./sonner.css";
@import "./zendesk.css";
@import "./fonts.css";

button {
  cursor: pointer;
}

.logo-animation:hover #logo-dot {
  animation: logoMove 0.9s ease-in-out 0.1s alternate 6;
}
