/* hack for zendesk
this allows us to use square or circle launcher type so we can use the snippet on marketing site

*/

/* this stops the chat window from floating above the default launcher  */
iframe[name="Messaging window"] {
  inset: auto 24px 24px auto !important;
}

/* hides the default launcher  */
@media screen and (max-width: 58rem) {
  iframe[id="launcher"] {
    display: none !important;
  }
}

/* enables chat window to go full screen in mobile view (whichhappends by default) but we are overriding that inset above,
this re-enables that behaviour 
*/
@media screen and (max-width: 549px) {
  iframe[name="Messaging window"] {
    inset: 0px !important;
  }
}

/* hides the 'Can we help? popup  */
iframe[title="Message from company"] {
  display: none !important;
}

/* hides the close button of popup  */
iframe[title="Close message"] {
  display: none !important;
}

/* hides the Number of unread messages popup  */
iframe[title="Number of unread messages"] {
  display: none !important;
}

/* hack for zendesk */
