.radio {
  cursor: pointer;
  user-select: none;
  text-align: left;
}

.radio input {
  display: none;
}

.radio input + span {
  position: relative;
  padding-left: 1.6rem;
}

.radio input + span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  border: 1px solid #f9fafb;
  background: #f9fafb;
}

.radio input + span::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background: var(--colors-brand-yamcha);
  position: absolute;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0, 0);
  transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.radio input:checked + span:after {
  opacity: 1;
  transform: scale(1, 1);
}

.radio--xl input + span::before {
  width: 1.6rem;
  height: 1.6rem;
  top: 0rem;
  left: 0rem;
}

.radio--xl input + span::after {
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  left: 0.3rem;
}

.radio--no-label {
  font-size: 0px;
  bottom: 0.875rem;
  left: 0.5rem;
}

.radio--xl .radio--no-label {
  bottom: 1rem;
  left: 0;
}

.radio--xl input + span {
  position: relative;
  padding-left: 1.8rem;
  padding-top: 0;
}
